import React from 'react';
import {useStore} from 'effector-react';
import {Button, Typography} from '@beeline/design-system-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {NotFound, NotFoundTitle, NotFoundCaption} from 'components/NotFound';
import {Table, TableCell, TableCellContent, TableHead, TableString} from 'shared/ui/Table/Table';
import {$userActions} from 'shared/model/user';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {rowContent} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {EAccessActions} from 'shared/const/actions';

import {ProjectString, ProjectTableWrap} from './style';
import {$projectListColumnFilter} from '../model';

export const availableFilters = [
    'project_ext_id',
    'open_date',
    'year',
    'bs_name',
    'pos_code',
    'bs_gfk',
    'types_project',
    'status',
    'branch',
    'region',
];

export const TableInfoContent = function ({row}) {
    const columnFilter = useStore($projectListColumnFilter);
    const userActions = useStore($userActions);
    const projectViewLinkActive = userHasPermission(EAccessActions.ProjectCard, userActions);
    const handleOnClick = () =>
        projectViewLinkActive ? navigationInvoked({to: `/projects/${row.id}`}) : undefined;

    return (
        <ProjectString onClick={handleOnClick} clickable={projectViewLinkActive}>
            {columnFilter.columns.map(
                (item) =>
                    item.visible && (
                        <TableCell key={item.name}>
                            <TableCellContent>
                                {rowContent(row, projectViewLinkActive)[item.name]}
                            </TableCellContent>
                        </TableCell>
                    ),
            )}
        </ProjectString>
    );
};

export default function ProjectPageTable({
    projects,
    sortParam,
    setSortParam,
    sortType,
    setSortType,
    filterDisplay,
    filterPosition,
    isSearching,
    resetFilters,
}) {
    const columnFilter = useStore($projectListColumnFilter);
    const filterIsAvailable = (item) => availableFilters.includes(item);

    function checkSortParam(param) {
        if (sortParam != param) {
            setSortType(true);
            return setSortParam(param);
        }
        if (sortType) {
            setSortType(false);
            return setSortParam(param);
        }
        setSortType(true);
        return setSortParam(param);
    }

    const sortColumn = (name) => {
        switch (name) {
            case 'project_ext_id':
                checkSortParam('project_ext_id');
                break;
            case 'open_date':
                checkSortParam('open_date');
                break;
            case 'year':
                checkSortParam('year');
                break;
            case 'bs_name':
                checkSortParam('bs_number');
                break;
            case 'pos_code':
                checkSortParam('pos_code');
                break;
            case 'bs_gfk':
                checkSortParam('bs_gfk');
                break;
            case 'types_project':
                checkSortParam('types_project');
                break;
            case 'branch':
                checkSortParam('branch');
                break;
            case 'region':
                checkSortParam('region');
                break;
            case 'status':
                checkSortParam('statuses_project');
                break;
            default:
                break;
        }
    };

    const handleResetFilters = () => {
        resetFilters();
    };
    return (
        <>
            {projects.length !== 0 ? (
                <ProjectTableWrap className="scrollbar-horizontal">
                    <Table minWidth="unset">
                        <TableString>
                            {columnFilter.columns.map(
                                (item, index) =>
                                    item.visible && (
                                        <TableCell
                                            width={item.width}
                                            key={item.name}
                                            minWidth={item.minWidth}
                                            onClick={() => {
                                                sortColumn(item.name);
                                            }}
                                            clickable
                                            hover={
                                                filterIsAvailable(item.name) &&
                                                index !== filterDisplay
                                            }
                                            alreadyHasSorting={filterDisplay !== null}
                                            arrowUp={index == filterDisplay && filterPosition}
                                            arrowDown={index == filterDisplay && !filterPosition}
                                        >
                                            <TableHead>
                                                <Typography variant="subtitle3">
                                                    {item.label}
                                                </Typography>
                                            </TableHead>
                                        </TableCell>
                                    ),
                            )}
                        </TableString>
                        {projects.map((item) => (
                            <TableInfoContent key={item.id} row={item} />
                        ))}
                    </Table>
                </ProjectTableWrap>
            ) : (
                <NotFound height="687px">
                    {isSearching ? (
                        <>
                            <NotFoundTitle>По вашему запросу ничего не найдено</NotFoundTitle>
                            <NotFoundCaption>
                                Попробуйте сократить запрос или задать его по-другому
                            </NotFoundCaption>
                        </>
                    ) : (
                        <>
                            <NotFoundTitle>По заданным фильтрам ничего не найдено</NotFoundTitle>
                            <NotFoundCaption>
                                Попробуйте изменить параметры или сбросьте фильтры
                            </NotFoundCaption>
                            <Button className="mt-3" variant="plain" onClick={handleResetFilters}>
                                Сбросить
                            </Button>
                        </>
                    )}
                </NotFound>
            )}
        </>
    );
}
