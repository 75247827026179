import {VAT_RATE} from '../const/numbers';

export const MODAL_APPROVE_COMPLETED_WORKS_AGREEMENT_TITLE =
    'Вы уверены, что хотите согласовать ВВР?';
export const MODAL_UPDATE_COMPLETED_WORKS_AGREEMENT1_TITLE = 'Обновить ВВР?';
export const MODAL_APPROVE_COMPLETED_WORKS_AGREEMENT_SUBTITLE =
    'Отменить принятое решение будет невозможно';
export const MODAL_UPDATE_COMPLETED_WORKS_AGREEMENT_SUBTITLE =
    'ВВР создана по устаревшему документу и будет обновлена. Пункты работ устаревшего документа будут исключены, для добавления станут доступны пункты работ актуального документа.';

// buttons
export const CANCEL_BUTTON_TITLE = 'Отменить';
export const CONFIRM_BUTTON_TITLE = 'Согласовать';
export const DUPLICATE_BUTTON_TITLE = 'Дублировать';
export const DELETE_BUTTON_TITLE = 'Удалить';
export const PROJECT_TITLE = 'Проект';
export const REFRESH_COMPLETED_WORKS_TITLE = 'Обновить ВВР';
export const FOR_AGREEMENT_BUTTON_TITLE = 'На согласование';
export const COPY_BUTTON_TITLE = 'Скопировать';

// titles
export const CREATION_DATE = 'Дата создания';
export const APPROVAL_DATE = 'Дата утверждения';
export const CONFORMING_DATE = 'Дата согласования';
export const SIGNING_DATE = 'Дата подписания';
export const ISSUANCE_DATE = 'Дата выдачи';
export const RESPONSE_DATE = 'Дата ответа';
export const DEADLINE_DATE = 'Срок выполнения';
export const DIADOC_LINK_TITLE = 'Ссылки на Диадок';
export const NUMBER_TITLE = 'Номер';
export const LINK_TITLE = 'Ссылка';
export const CARD_TITLE = 'Карточка';
export const PR_TITLE = 'PR';
export const SIGNING_TITLE = 'ЭДО';
export const PO_TITLE = 'ЗП';
export const ERRORS_TITLE = 'Ошибки';
export const COMPLETED_WORKS_TITLE = 'ВВР';
export const ADDRESS_TITLE = 'Адрес';
export const SET_QUANTITY_TITLE = 'Укажите количество';
export const QUANTITY_EDITING_PROHIBITION_TITLE = 'Редактирование количества запрещено';
export const PRICE_EDITING_PROHIBITION_TITLE = 'Редактирование цены запрещено';
export const FILTERS_AND_SETTINGS_SIDESHEET_TITLE = 'Фильтры и настройки';
export const FILTERS_TITLE = 'Фильтры';
export const CONTRACT_TITLE = 'Договор';
export const CONTRACT_NUMBER_TITLE = 'Номер договора';
export const CONTRACTS_TITLE = 'Договоры';
export const REGION_TITLE = 'Регион';
export const SEARCH_TITLE = 'Поиск';
export const PROCUREMENT_EVENT_TITLE = 'Закупочное мероприятие';
export const CONTRACTOR_SHORT_TITLE = 'ГПО';
export const EXPIRY_RANGE_TITLE = 'Срок действия';
export const IS_ACTIVE_ON_DATE_TITLE = 'Действует на дату';
export const COLUMN_SETTINGS_TITLE = 'Настройка колонок';
export const LOTS_AND_BRANCHES_TITLE = 'Лоты и филиалы';
export const LOT_TITLE = 'Лот';
export const LOTS_TITLE = 'Лоты';
export const BRANCH_SEARCH_TITLE = 'Поиск филиала';
export const FROM_TITLE = 'от';
export const WORK_TYPE_TITLE = 'Тип работ';
export const JOB_TYPE_TITLE = 'Тип задания';
export const PROJECT_TYPE_TITLE = 'Тип проекта';
export const BUSINESS_RULES_PAGE_TITLE = 'Бизнес-правила установки признака APPROVE_ALLOWED';
export const COMMON_DESCRIPTION_SUBTITLE = 'Общее описание';
export const CREATE_TITLE = 'Создать';
export const EDIT_TITLE = 'Редактировать';
export const CURRENT_COMPLETED_WORKS_DONE_TITLE = 'Выполнено по ВВР';
export const PREV_COMPLETED_WORKS_DONE_TITLE = 'Выполнено по другим ВВР';
export const PO_NUMBER_TITLE = 'Номер ЗП';
export const UNUSUAL_PO_NUMBER_TITLE = 'Нестандартный ЦБО';
export const EDMC_TITLE = 'ЭДО КЗД';
export const EDMC_TEMPLATES_TITLE = 'ЭДО шаблоны';
export const DOWNLOAD_TITLE = 'Скачать';
export const DIADOC_LINKS_TITLE = 'Ссылки в Диадок';
export const KS2_TITLE = 'КС2';
export const KS3_TITLE = 'КС3';
export const INVOICE_TITLE = 'СФ';
export const GFK_CODE_TITLE = 'Код ГФК';
export const POSITION_NUMBER_TITLE = 'Номер позиции';
export const PAYMENT_TITLE = 'Оплата';
export const COMPLETED_WORKS_WIDGET_SSF_NUMBER_TITLE = 'Номер ССФ';
export const COMPLETED_WORKS_WIDGET_SSF_DATE_TITLE = 'Дата ССФ';
export const COMPLETED_WORKS_WIDGET_PLANED_DATE_TITLE = 'Плановая дата оплаты';
export const COMPLETED_WORKS_WIDGET_ACTUAL_DATE_TITLE = 'Фактическая дата оплаты';

// page titles
export const FILL_IN_PO_NUMBER_TITLE = 'Заполнить номер ЗП';

// Subtitles
export const ERRORS_SUBTITLE_TEXT = 'Пожалуйста, исправьте все ошибки';
export const HOW_TO_FIX_ERROR_TEXT = 'Как исправить ошибку';

export const SET_AGREED_PRICE_TEXT = 'Укажите цену по согласованию';
export const AGREED_PRICE_TEXT = 'Цена по согласованию';

// snackbar notifications
export const LINK_COPIED = 'Ссылка была скопирована в буфер обмена';
export const TEXT_COPIED = 'Текст скопирован в буфер обмена';
export const SSF_NUMBER_COPIED = 'Номер ССФ скопирован в буфер обмена';
export const PO_NUMBER_CREATED_SNACKBAR = 'Номер ЗП сохранён';

//buttons
export const SEND_TO_APPROVE = 'Отправить на проверку';
export const DISCARD_BUTTON_TITLE = 'Сбросить';
export const APPLY_BUTTON_TITLE = 'Применить';
export const GO_TO_BASIS_TITLE = 'Перейти в БАЗИС';

// agreements
export const ADDITIONAL_AGREEMENT_SHORT_TITLE = 'ДС';
export const ADDITIONAL_AGREEMENT_FULL_TITLE = 'Доп. соглашение';
export const AGREEMENT_ADDITION_TITLE = 'Дополнение';
export const AGREEMENT_TERMINATION_TITLE = 'Расторжение ДС';
export const TERMINATION_TITLE = 'Расторжение';
export const SUM_TITLE = 'Итого без НДС';
export const TAX_TITLE = `Налог на добавленную стоимость (НДС ${VAT_RATE}%):`;
export const NO_TAX_TITLE = 'НДС не облагается';
export const SHEET_TOTAL_SUM_TITLE = 'Итого по ведомости с НДС:';
export const SHEET_TOTAL_SUM_WITHOUT_TAX_TITLE = 'Итого по ведомости:';
export const AGREEMENT_SUM_TITLE = 'Итого по ДС без НДС:';
export const AGREEMENT_TOTAL_SUM_TITLE = 'Итого по ДС с НДС:';
export const AGREEMENT_TOTAL_SUM_WITHOUT_TAX_TITLE = 'Итого по ДС:';

//hints
export const PROHIBITED_COEFFICIENT_EDITING_HINT =
    'Невозможно редактирование коэффициентов, т.к. пункт закрыт в ВВР';
export const PROHIBITED_SHEET_DELETION_HINT =
    'Невозможно удалить ведомость, т.к. есть закрытые пункты в ВВР';
export const PROHIBITED_OBJECT_DELETION_HINT =
    'Невозможно удалить объект, т.к. есть закрытые пункты в ВВР';
export const PROHIBITED_SPEC_DELETION_HINT = 'Невозможно удалить пункт, т.к. он закрыт в ВВР';
export const IRREVERSIBLE_ACTION_HINT = 'Отменить это действие будет невозможно';
export const DELETION_CONFIRMING_HINT = 'Вы уверены, что хотите удалить объект со всеми работами?';
export const SHEET_DELETION_CONFIRMING_HINT =
    'Вы уверены, что хотите удалить ведомость со всеми работами?';

// labels
export const DOCUMENT_TYPE_LABEL = 'Тип документа';
export const UNNECESSARY_TEXTAREA_LABEL = 'Комментарий (при необходимости)';
