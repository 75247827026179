import styled, {css} from 'styled-components/macro';
import {TableString} from 'shared/ui/Table/Table';
import {Icon} from '@beeline/design-system-react';

export const VirList = styled.div`
    border: 1px solid #d1d1d1;
    border-radius: 4px;

    & > * {
        tr {
            &:first-child {
                border-radius: 4px 4px 0 0;
            }
        }

        &:last-child {
            border-bottom: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    --min-w: none;
`;
const TableStringTCPMixin = (props) => css`
    td {
        min-height: 56px;
    }

    &:hover {
        background-color: #ededef;
    }

    ${props.checked &&
    css`
        background-color: #ededef;
    `}
    ${props.notSelectable &&
    css`
        background-color: var(--color-status-info-background);
        pointer-events: none;
    `}
`;

export const TableStringTCP = styled(TableString)`
    ${(props) => TableStringTCPMixin(props)}
`;

export const NameCell = styled.div<{needAlignmentToRight: boolean}>`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: revert;
    align-items: center;
    justify-content: flex-start;
    margin-right: 30px;
    ${(p) => p.needAlignmentToRight && 'margin-left: 33px;'}
`;

export const IconButtonWrapper = styled.div`
    flex: 0 0 24px;
    display: flex;
    align-items: center;
    margin-left: auto;
`;

export const StyledIcon = styled(Icon)`
    margin-right: 8px;
    cursor: pointer;
`;
