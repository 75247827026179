import React from 'react';
import {Link} from 'react-router-dom';
import {useUnit} from 'effector-react';
import {Skeleton, Typography} from '@beeline/design-system-react';

import {InformationalUnit, InformationalUnitSkeleton} from 'Entities/InformationalUnit';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$userActions} from 'shared/model/user';
import {formatDate, formatDateHours} from 'shared/helpers/formatHelper';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {JobType} from 'shared/types/jobsTypes';
import {EAccessActions} from 'shared/const/actions';
import {
    ADDRESS_TITLE,
    DEADLINE_DATE,
    FROM_TITLE,
    ISSUANCE_DATE,
    JOB_TYPE_TITLE,
    LOT_TITLE,
    PROCUREMENT_EVENT_TITLE,
    RESPONSE_DATE,
    WORK_TYPE_TITLE,
} from 'shared/constants';

import * as S from './style';

interface JobPageInfoProps {
    jobData: JobType | null;
}

export const JobPageInfo = ({jobData}: JobPageInfoProps) => {
    const userActions = useUnit($userActions);
    if (!jobData) return <JobPageInfoSkeleton />;

    const contractLinkAllowed = userHasPermission(EAccessActions.ContractCard, userActions);

    const {
        approved_at,
        bs: {bs_number, bs_address, bs_name},
        contract,
        created_at,
        job_task_type_name,
        period_execution,
        purchase_lot: {name: lotName},
        purchasing_event: {type, number},
        project_type_name,
    } = jobData;

    return (
        <WrapperFlex flexDirection="column" padding="36px 0 0" gap="16px">
            <Typography variant="h5">{`БС ${bs_number} - ${bs_name}`}</Typography>
            <S.InfoGridWrapper>
                <InformationalUnit title={ADDRESS_TITLE} gridArea="address">
                    {bs_address}
                </InformationalUnit>
                <InformationalUnit title={ISSUANCE_DATE} gridArea="creationDate">
                    {formatDateHours(created_at)}
                </InformationalUnit>
                <InformationalUnit title={RESPONSE_DATE} gridArea="approvingDate">
                    {formatDateHours(approved_at)}
                </InformationalUnit>
                <InformationalUnit title={PROCUREMENT_EVENT_TITLE} gridArea="purchase">
                    {`${number} - ${type}`}
                </InformationalUnit>
                <InformationalUnit title={LOT_TITLE} gridArea="lot">
                    {lotName}
                </InformationalUnit>
                <InformationalUnit title={WORK_TYPE_TITLE} gridArea="workType">
                    {project_type_name}
                </InformationalUnit>
                <InformationalUnit title={JOB_TYPE_TITLE} gridArea="jobType">
                    {job_task_type_name}
                </InformationalUnit>
                <InformationalUnit title={DEADLINE_DATE} gridArea="deadline">
                    {`${period_execution} рабочих дней с момента получения исходных данных`}
                </InformationalUnit>
                <S.GridContractWrapper>
                    <Typography variant="body2">
                        {contractLinkAllowed ? (
                            <Link to={`/contracts/${contract.id}`}>{`${
                                contract.number
                            } от ${formatDate(contract.date_conclusion)}`}</Link>
                        ) : (
                            `${contract.number} ${FROM_TITLE} ${formatDate(
                                contract.date_conclusion,
                            )}`
                        )}
                    </Typography>
                </S.GridContractWrapper>
            </S.InfoGridWrapper>
        </WrapperFlex>
    );
};

const JobPageInfoSkeleton = () => {
    return (
        <WrapperFlex flexDirection="column" padding="36px 0 0" gap="16px">
            <Skeleton variant="line" width={200} height={28} />
            <S.InfoGridWrapper>
                <InformationalUnitSkeleton gridArea="address" />
                <InformationalUnitSkeleton gridArea="creationDate" />
                <InformationalUnitSkeleton gridArea="approvingDate" />
                <InformationalUnitSkeleton gridArea="purchase" />
                <InformationalUnitSkeleton gridArea="lot" />
                <InformationalUnitSkeleton gridArea="workType" />
                <InformationalUnitSkeleton gridArea="jobType" />
                <InformationalUnitSkeleton gridArea="deadline" />
                <Skeleton height={20} width={250} variant="text" grid-area="contract" />
            </S.InfoGridWrapper>
        </WrapperFlex>
    );
};
