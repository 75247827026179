import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGate, useStore, useUnit} from 'effector-react';
import {Button, Option, TableBody, Typography} from '@beeline/design-system-react';

import {TableBase} from 'Entities/TableBase';
import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';
import {TableHeader} from 'Features/TableHeader';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import Select, {SelectItem} from 'shared/ui/Select/Select';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$isUserGpo, $userActions} from 'shared/model/user';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';
import {TDocumentsListItem} from 'shared/types/documentTypes';
import {CustomTableData, InnerTableRow, OuterTableRow} from 'shared/styles/commonStyle';
import {IPaginationData} from 'shared/types/commonTypes';

import * as S from './style';
import {columns} from './columns';
import {
    changeDocumentTypeSearchValue,
    changePrimeContractorSearchValue,
    changeSelectedDocumentType,
    changeSelectedPrimeContractor,
    ChangingSelectValueType,
    DocumentTableGate,
    documentTableStore,
    resetAllSelects,
    resetDocumentTypeSearchValue,
    resetPrimeContractorSearchValue,
    rowExpanded,
    setPage,
    setPageSize,
} from '../../model/documentTable';

export const DocumentTable = () => {
    const {projectId} = useParams<{projectId: string}>();
    const navigate = useNavigate();

    useGate(DocumentTableGate, projectId);

    const {
        documentsStore,
        primeContractorsStore,
        documentTypesStore,
        paginationCount,
        tableIsLoading,
        documentListParams: {page, page_size, selectedDocumentType, selectedPrimeContractor},
        primeContractorSearchValue,
        documentTypeSearchValue,
        expandedRowList,
    } = useStore(documentTableStore);
    const [isUserGpo, userActions] = useUnit([$isUserGpo, $userActions]);

    const createButtonVisible =
        userHasPermission(EAccessActions.DocumentsCreation, userActions) ||
        userHasPermission(EAccessActions.FinancialDocumentsCreation, userActions);

    const handleCreateDocument = () => {
        navigate(`document-creation`);
    };

    const handleChangePrimeContractor = (
        value: Option<String>[],
        targetItem: Option<String> | null,
        checked: boolean,
    ) => {
        changeSelectedPrimeContractor({value, targetItem, checked} as ChangingSelectValueType);
    };

    const handleChangeDocumentType = (
        value: Option<String>[],
        targetItem: Option<String> | null,
        checked: boolean,
    ) => {
        changeSelectedDocumentType({value, targetItem, checked} as ChangingSelectValueType);
    };

    const handleResetFilters = () => {
        resetAllSelects();
    };
    const handleClickRow = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
        e.stopPropagation();
        rowExpanded(id);
    };
    const paginationData: IPaginationData = {
        total: paginationCount,
        isLoading: tableIsLoading,
        page,
        pageSize: page_size,
        setPage,
        setPageSize,
    };
    const visibleColumns = isUserGpo ? columns.filter((col) => col.name !== 'contractor') : columns;
    return (
        <>
            <S.SelectWrapper>
                {createButtonVisible && (
                    <Button variant="outlined" size="medium" onClick={handleCreateDocument}>
                        Создать
                    </Button>
                )}
                {!isUserGpo && (
                    <>
                        <S.PrimeContractorWrapper>
                            <Select
                                value={selectedPrimeContractor}
                                valueKey="id"
                                valueLabel="value"
                                title="ГПО"
                                handleEdit={handleChangePrimeContractor}
                                searchValue={primeContractorSearchValue}
                                setSearch={changePrimeContractorSearchValue}
                                notAll={primeContractorsStore.length >= 0}
                                handleResetSearch={resetPrimeContractorSearchValue}
                                search
                                multiple
                                fullWidth
                                fullWidthList
                            >
                                {primeContractorsStore.length > 0 ? (
                                    primeContractorsStore.map((item) => (
                                        <SelectItem
                                            key={item.id as string}
                                            value={item}
                                            multiple={false}
                                        >
                                            {item.value}
                                        </SelectItem>
                                    ))
                                ) : (
                                    <WrapperFlex justifyContent="center">
                                        <Typography variant="subtitle3">Нет результатов</Typography>
                                    </WrapperFlex>
                                )}
                            </Select>
                        </S.PrimeContractorWrapper>
                    </>
                )}
                <S.TypeWrapper>
                    <Select
                        value={selectedDocumentType}
                        valueKey="id"
                        valueLabel="value"
                        title="Тип документа"
                        handleEdit={handleChangeDocumentType}
                        searchValue={documentTypeSearchValue}
                        setSearch={changeDocumentTypeSearchValue}
                        notAll={documentTypesStore.length >= 0}
                        handleResetSearch={resetDocumentTypeSearchValue}
                        search
                        multiple
                        fullWidth
                        fullWidthList
                    >
                        {documentTypesStore.length > 0 ? (
                            documentTypesStore.map((item) => (
                                <SelectItem key={item.id as string} value={item} multiple={false}>
                                    {item.value}
                                </SelectItem>
                            ))
                        ) : (
                            <WrapperFlex justifyContent="center">
                                <Typography variant="subtitle3">Нет результатов</Typography>
                            </WrapperFlex>
                        )}
                    </Select>
                </S.TypeWrapper>
            </S.SelectWrapper>
            {documentsStore.length > 0 || tableIsLoading ? (
                <TableBase minTableWidth="1300px" paginationData={paginationData}>
                    <TableHeader columns={visibleColumns} />
                    {tableIsLoading ? (
                        <TableBodySkeleton<TDocumentsListItem> columns={visibleColumns} />
                    ) : (
                        <TableBody>
                            {documentsStore.map((item) => (
                                <React.Fragment key={item.id}>
                                    <OuterTableRow
                                        onClick={(e) => handleClickRow(e, item.id)}
                                        dense
                                        hover={(item.previous_version?.length ?? 0) > 0}
                                    >
                                        {visibleColumns.map((col) => (
                                            <CustomTableData key={`${col.name}${item.id}`}>
                                                {col.render &&
                                                    col.render(
                                                        item,
                                                        userActions,
                                                        expandedRowList.includes(item.id),
                                                    )}
                                            </CustomTableData>
                                        ))}
                                    </OuterTableRow>
                                    {expandedRowList.includes(item.id) &&
                                        item.previous_version &&
                                        item.previous_version.map((prevItem) => (
                                            <InnerTableRow key={prevItem.id}>
                                                {visibleColumns.map((col) => (
                                                    <CustomTableData
                                                        key={`inner_${col.name}${item.id}`}
                                                        dense
                                                    >
                                                        {col.render &&
                                                            col.render(
                                                                prevItem,
                                                                userActions,
                                                                false,
                                                            )}
                                                    </CustomTableData>
                                                ))}
                                            </InnerTableRow>
                                        ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    )}
                </TableBase>
            ) : (
                <NotFound height="500px">
                    <NotFoundTitle>По заданным фильтрам ничего не найдено</NotFoundTitle>
                    <NotFoundCaption>
                        Попробуйте изменить параметры или сбросьте фильтры
                    </NotFoundCaption>
                    <Button className="mt-3" variant="plain" onClick={handleResetFilters}>
                        Сбросить
                    </Button>
                </NotFound>
            )}
        </>
    );
};
