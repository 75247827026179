import React from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {ModalFactory} from 'Features/modalFactory/ui';
import {ActionButtonsUnit} from 'Features/ActionButtonsUnit';
import {ExecutionButtonGroup} from 'widgets/ExecutionButtonGroup';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import HeaderText from 'shared/ui/deprecated/HeaderText/HeaderText';
import Label from 'shared/ui/deprecated/Label/Label';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {
    CANCEL_BUTTON_TITLE,
    CONFIRM_BUTTON_TITLE,
    MODAL_APPROVE_COMPLETED_WORKS_AGREEMENT_SUBTITLE,
    MODAL_APPROVE_COMPLETED_WORKS_AGREEMENT_TITLE,
    MODAL_UPDATE_COMPLETED_WORKS_AGREEMENT1_TITLE,
    MODAL_UPDATE_COMPLETED_WORKS_AGREEMENT_SUBTITLE,
    REFRESH_COMPLETED_WORKS_TITLE,
} from 'shared/constants';

import {
    $completedWorksViewPageStore,
    $completedWorksWorkflowStore,
    acceptButtonClicked,
    confirmModalPositiveBtnClicked,
    confirmModalToggled,
    documentUpdated,
    downloadButtonClicked,
    editDocumentClicked,
    returnButtonClicked,
    updateDocumentModalToggled,
    workflowButtonClicked,
} from '../model';

export const PageTitle = () => {
    const {
        completedWorksSheet,
        confirmModalWindowShown,
        updateModalWindowShown,
        currentCompletion,
    } = useStore($completedWorksViewPageStore);
    const {taskData, buttonsDisabled, positiveButtonDisabled, negativeButtonDisabled} = useStore(
        $completedWorksWorkflowStore,
    );

    if (!completedWorksSheet) return null;

    const {editor, gpo_short_name, project, number, status, updated_at} = completedWorksSheet;

    const projectLink = `/projects/${project.id}`;

    const {project_number: projectNumber} = project;
    const completedWorksSheetNumber = number;

    const headerText = `ВВР ${number} - ${currentCompletion}% ${gpo_short_name}`;

    return (
        <WrapperFlex
            justifyContent="space-between"
            alignItems="flex-start"
            gap="16px"
            flexWrap="wrap"
        >
            <div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/">Проекты</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={projectLink}>{`Проект ${projectNumber}`}</Link>
                    </BreadcrumbItem>

                    <BreadcrumbItem>{`ВВР ${completedWorksSheetNumber}`}</BreadcrumbItem>
                </Breadcrumb>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center" gap="8px">
                        <HeaderText className="me-2" component="h4">
                            {headerText}
                        </HeaderText>
                        <Label outline variant={getLabelType(status.token)}>
                            {status.name}
                        </Label>
                    </WrapperFlex>
                </WrapperOffset>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center" gap="8px">
                        <Typography variant="caption">
                            {editor.full ?? ''} редактировал{' '}
                            {updated_at && formatDateHours(updated_at as unknown as string)}{' '}
                        </Typography>
                    </WrapperFlex>
                </WrapperOffset>
            </div>
            <ExecutionButtonGroup
                documentStatus={status}
                taskData={taskData}
                buttonsDisabled={buttonsDisabled}
                handleAcceptButton={acceptButtonClicked}
                handleReturnButton={returnButtonClicked}
                handleWorkflowButton={workflowButtonClicked}
                positiveButtonDisabled={positiveButtonDisabled}
                negativeButtonDisabled={negativeButtonDisabled}
                handleEditButton={editDocumentClicked}
                handleDownloadButton={downloadButtonClicked}
            />
            <ModalFactory
                open={confirmModalWindowShown}
                title={MODAL_APPROVE_COMPLETED_WORKS_AGREEMENT_TITLE}
                description={MODAL_APPROVE_COMPLETED_WORKS_AGREEMENT_SUBTITLE}
            >
                <ActionButtonsUnit
                    negativeBtnLabel={CANCEL_BUTTON_TITLE}
                    positiveBtnLabel={CONFIRM_BUTTON_TITLE}
                    handleNegativeBtn={() => confirmModalToggled(false)}
                    handlePositiveBtn={confirmModalPositiveBtnClicked}
                />
            </ModalFactory>
            <ModalFactory
                open={updateModalWindowShown}
                title={MODAL_UPDATE_COMPLETED_WORKS_AGREEMENT1_TITLE}
                description={MODAL_UPDATE_COMPLETED_WORKS_AGREEMENT_SUBTITLE}
            >
                <ActionButtonsUnit
                    negativeBtnLabel={CANCEL_BUTTON_TITLE}
                    positiveBtnLabel={REFRESH_COMPLETED_WORKS_TITLE}
                    disableAllButtons={buttonsDisabled}
                    handleNegativeBtn={() => updateDocumentModalToggled(false)}
                    handlePositiveBtn={() => documentUpdated()}
                />
            </ModalFactory>
        </WrapperFlex>
    );
};
