import React from 'react';
import {Link} from 'react-router-dom';
import {Icon, Label} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {AllStatuses} from '@beeline/design-system-react/types/types/status';

import {DownloadingButtonWithCard} from 'widgets/DownloadingCard';
import {IProcessesColumn} from 'shared/types/tableTypes';
import {TDocumentsListItem} from 'shared/types/documentTypes';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

export const columns: IProcessesColumn<TDocumentsListItem>[] = [
    {
        name: 'toggle',
        label: '',
        width: '52px',
        render: ({previous_version}, userActions, isOpen) =>
            (previous_version?.length ?? 0) > 0 ? (
                <Icon iconName={isOpen ? Icons.NavArrowUp : Icons.NavArrowDown} size="medium" />
            ) : undefined,
    },
    {
        name: 'documentType',
        label: 'Тип документа',
        render: ({subtypes_document, id}, userActions) => {
            const hasPermission =
                userActions && userHasPermission(EAccessActions.DocumentsCard, userActions);
            return (
                <TooltipedSpan title={subtypes_document} lineCount={2}>
                    {hasPermission ? (
                        <Link to={`/documents/${id}`}>{subtypes_document}</Link>
                    ) : (
                        subtypes_document
                    )}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'attachments',
        label: 'Вложения',
        render: ({document_file}) => {
            const attachmentList = document_file.map((item) => item.file_name).join(', ');
            return (
                <TooltipedSpan title={attachmentList} lineCount={2}>
                    {attachmentList}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'download',
        label: '',
        width: '52px',
        render: ({document_file}) => <DownloadingButtonWithCard documentList={document_file} />,
    },
    {
        name: 'contractor',
        label: 'ГПО',
        render: ({organization_gpo}) => (
            <TooltipedSpan
                title={organization_gpo ?? '—'}
                lineCount={1}
                disableHover={!organization_gpo}
            >
                {organization_gpo ?? '—'}
            </TooltipedSpan>
        ),
    },
    {
        name: 'creationDate',
        label: 'Дата создания',
        width: '156px',
        render: ({created_at}) => (
            <TooltipedSpan title={formatDateHours(created_at)} lineCount={1}>
                {formatDateHours(created_at)}
            </TooltipedSpan>
        ),
    },
    {
        name: 'requestDate',
        label: 'Дата ответа',
        width: '156px',
        render: ({approved_at}) => (
            <TooltipedSpan
                title={formatDateHours(approved_at)}
                lineCount={1}
                disableHover={!approved_at}
            >
                {formatDateHours(approved_at)}
            </TooltipedSpan>
        ),
    },
    {
        name: 'version',
        label: 'Версия',
        width: '84px',
        render: ({prefix_version, version}) => {
            const versionString = `${prefix_version.toString()}.${version.toString()}`;
            return (
                <TooltipedSpan title={versionString} lineCount={1}>
                    {versionString}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'author',
        label: 'Автор',
        width: '204px',
        render: ({editor}) => (
            <TooltipedSpan title={editor.full} lineCount={1}>
                {editor.full}
            </TooltipedSpan>
        ),
    },
    {
        name: 'status',
        label: 'Статус',
        width: '166px',
        render: ({status}) =>
            !!status ? (
                <Label title={status.name} type={getLabelType(status.token) as AllStatuses} />
            ) : (
                '—'
            ),
    },
];
