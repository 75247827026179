import React from 'react';
import {Link} from 'react-router-dom';
import {Label} from '@beeline/design-system-react';
import {AllStatuses} from '@beeline/design-system-react/types/types/status';

import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import {IColumnWithActions} from 'shared/types/tableTypes';
import {TJobListItem} from 'shared/types/jobsTypes';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';
import {StyledSpan} from 'shared/styles/commonStyle';

export const jobListColumns: IColumnWithActions<TJobListItem>[] = [
    {
        name: 'jobNumber',
        label: 'Задание',
        hasSorting: false,
        width: '120px',
        render: ({job_tasks_number, job_task_type_name}) => (
            <div>
                <StyledSpan lineCount={1}>{job_tasks_number}</StyledSpan>
                <StyledSpan className="inactiveText" lineCount={1}>
                    {job_task_type_name}
                </StyledSpan>
            </div>
        ),
    },
    {
        name: 'contractor',
        label: 'ГПО',
        hasSorting: false,
        render: ({contractor_short_name}) => (
            <TooltipedSpan title={contractor_short_name} lineCount={2}>
                {contractor_short_name}
            </TooltipedSpan>
        ),
    },
    {
        name: 'purchasingEventNumber',
        label: '№ ЗМ',
        hasSorting: false,
        width: '120px',
        render: ({purchasing_event}) => purchasing_event.number,
    },
    {
        name: 'lot',
        label: 'Лот',
        hasSorting: false,
        width: '120px',
        render: ({purchase_lot}) => purchase_lot.name,
    },
    {
        name: 'contract',
        label: 'Договор',
        hasSorting: false,
        render: ({contract}, userActions) => {
            const hasAccess =
                userActions && userHasPermission(EAccessActions.ContractCard, userActions);
            const data = (
                <TooltipedSpan title={contract.number} lineCount={2}>
                    {contract.number}
                </TooltipedSpan>
            );
            return hasAccess ? (
                <Link
                    to={`/contracts/${contract.id}`}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {data}
                </Link>
            ) : (
                data
            );
        },
    },
    {
        name: 'creationDate',
        label: 'Дата выдачи',
        hasSorting: false,
        width: '150px',
        render: ({created_at}) => formatDateHours(created_at),
    },
    {
        name: 'answerDate',
        label: 'Дата ответа',
        hasSorting: false,
        width: '150px',
        render: ({approved_at}) => formatDateHours(approved_at),
    },
    {
        name: 'status',
        label: 'Статус',
        hasSorting: false,
        render: ({status}) => (
            <Label title={status.name} type={getLabelType(status.token) as AllStatuses} />
        ),
    },
];
