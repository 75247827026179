import React from 'react';
import {useLocation} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Icon, Search, TextField, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {LabelRadio} from 'Features/LabelRadio';
import {PrimeContractorsTable} from 'widgets/PrimeContractorsTable';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {SelectItem} from 'shared/ui/Select/Select';
import {PurchasingEventType} from 'shared/types/purchasesTypes';

import * as S from './style';
import {setTpiStore} from '../../model';
import {
    $jobCreationPageStore,
    $purchasingEventTypesList,
    jobProcessGateStage1,
    setButtonsDisabled,
    setPage,
    setPageSize,
    setSearchValue,
    setSelectedEventType,
    setSelectedJobTaskType,
    setModelCost,
    prepareStage1Data,
    resetSearchValue,
    setJobTaskTypeError,
    setModelCostError,
    setSelectedTpiData,
    setTpiSelectError,
} from '../../model/stage1';

interface Stage1Props {
    onChangeStage: (num: number) => void;
}

export const Stage1 = ({onChangeStage}: Stage1Props) => {
    useGate(jobProcessGateStage1);
    const location = useLocation();
    const {preliminary_organization: preliminaryPrimeContractor} = location.state;

    const {
        count,
        page,
        pageSize,
        primeContractorsData,
        primeContractorsDataIsLoading,
        taskTypes,
        selectedEventType,
        selectedJobTaskType,
        buttonsDisabled,
        modelCost,
        searchValue,
        selectedTpiData,
        errors,
    } = useStore($jobCreationPageStore);
    const list = useStore($purchasingEventTypesList);

    if (!list) return null;

    const handleChangePurchasingEventType = (value: PurchasingEventType) => {
        setSelectedEventType(value);
    };

    const handleSelectTaskType = (activeTaskTypeCode: string) => {
        setSelectedJobTaskType(activeTaskTypeCode);
    };

    const handleTransitionSecondStage = (biddingId: string, id: string) => {
        if (
            modelCost === '' ||
            selectedJobTaskType === '0' ||
            !selectedTpiData.hasOwnProperty(biddingId)
        ) {
            setButtonsDisabled(true);
            if (modelCost === '') {
                setModelCostError(true);
            }
            if (selectedJobTaskType === '0') {
                setJobTaskTypeError(true);
            }
            if (!selectedTpiData.hasOwnProperty(biddingId)) {
                setTpiSelectError(biddingId);
            }
        } else {
            const {id: tpi_id, remote_territory_id} = selectedTpiData[biddingId];
            prepareStage1Data(biddingId);
            tpi_id && setTpiStore({tpi_id: tpi_id, remote_territory_id: remote_territory_id});
            onChangeStage(2);
        }
    };

    const handleChangeModelCost = (e: React.ChangeEvent<HTMLInputElement>) => {
        const result = e.target.value;
        if (result.split('.').length === 1 || result.split('.')[1].length < 3) {
            setModelCost(result);
        }
    };

    return (
        <>
            <Typography className="mb-3 pb-1" variant="h4">
                Выберите генерального подрядчика
            </Typography>
            <S.FilterContainer>
                <S.TopFilterContainer>
                    <S.JobTypeContainer>
                        <Typography className="inactiveText mb-1" variant="body3">
                            Тип задания
                        </Typography>
                        <LabelRadio
                            data={taskTypes}
                            activeLabelCode={selectedJobTaskType}
                            onSelectActiveLabel={handleSelectTaskType}
                        />
                        {errors.jobTypeError && (
                            <S.ErrorMessage variant="caption">Выберите тип задания</S.ErrorMessage>
                        )}
                    </S.JobTypeContainer>
                    <S.PriceContainer>
                        <WrapperFlex
                            className="me-4"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {preliminaryPrimeContractor && (
                                <>
                                    <Icon className="me-2" iconName={Icons.Info} color="blue" />
                                    <WrapperFlex
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        className="pt-1"
                                    >
                                        <Typography className="inactiveText" variant="body3">
                                            Предварительное распределение ГП
                                        </Typography>
                                        <Typography variant="body2">
                                            {preliminaryPrimeContractor.toUpperCase()}
                                        </Typography>
                                    </WrapperFlex>
                                </>
                            )}
                        </WrapperFlex>

                        <S.ModelPriceContainer>
                            <TextField
                                className="fieldHelperPosition"
                                label="Модельная стоимость"
                                type="number"
                                min={0.01}
                                step="0.01"
                                helperText={
                                    errors.modelCostError
                                        ? 'Укажите модельную стоимость'
                                        : undefined
                                }
                                helperPosition="absolute"
                                value={modelCost}
                                onChange={handleChangeModelCost}
                                error={errors.modelCostError}
                                fullWidth
                            />
                        </S.ModelPriceContainer>
                    </S.PriceContainer>
                </S.TopFilterContainer>
                <S.BottomFilterContainer>
                    <S.StyledSelect
                        flex="0 0 294px"
                        value={selectedEventType}
                        valueKey="id"
                        valueLabel="name"
                        title="Тип закупочного мероприятия"
                        handleEdit={handleChangePurchasingEventType}
                        fullWidth
                        fullWidthList
                    >
                        {list?.map((item) => (
                            <SelectItem
                                key={item.id}
                                value={item}
                                selected={selectedEventType ?? []}
                                multiple={false}
                            >
                                {item.name}
                            </SelectItem>
                        ))}
                    </S.StyledSelect>
                    <Search
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onClear={() => resetSearchValue()}
                        placeholder="Поиск по названию, ИНН, номеру договора"
                        fullWidth
                    />
                </S.BottomFilterContainer>
            </S.FilterContainer>
            <PrimeContractorsTable
                data={primeContractorsData}
                selectedTpiData={selectedTpiData}
                setSelectedTpiData={setSelectedTpiData}
                dataIsLoading={primeContractorsDataIsLoading}
                count={count}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                handleTransitionSecondStage={handleTransitionSecondStage}
                selectError={errors.tpiSelectError}
                buttonsDisabled={buttonsDisabled}
            />
        </>
    );
};
