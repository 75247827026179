import {combine, forward, sample} from 'effector';
import {set, unset} from 'lodash';
import {v4 as uuidv4} from 'uuid';

import {
    $selectedContract,
    $selectedProject,
    $selectedTpi,
    $selectedWorkObject,
    $selectedWorkType,
} from 'pages/VirStart/model';
import {forwardPayload} from 'shared/helpers/effector';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';
import {getAgreementType} from 'shared/helpers/additionalAgreements';

import {
    $activeAccordion,
    $allowToSetPrepayment,
    $createAdditionalAgreementData,
    $currentLocationToAddTpis,
    $loadingAgreement,
    ConstructorGate,
    createAdditionalAgreementFx,
    createEmptyVir,
    createNewAdditionalAgreement,
    deleteVir,
    deleteWorkObject,
    deleteWorkSpecification,
    initialState,
    setAttachmentToVir,
    setCommentToAdditionalAgreement,
    parentSigningDateChanged,
    updateAdditionalAgreementFx,
    updatePrepayment,
    $agreementType,
} from './stores';
import {
    extractWorkObjects,
    getAllVirsSpecs,
    getPathToVir,
    isEveryPrepaymentSpecHasDismantlingCoefficients,
} from '../helpers';

$loadingAgreement
    .on(createAdditionalAgreementFx.pending, forwardPayload())
    .on(updateAdditionalAgreementFx.pending, forwardPayload());

const eventsTriggerPrepayment = [
    ConstructorGate.open,
    deleteWorkObject,
    deleteWorkSpecification,
    deleteVir,
    $createAdditionalAgreementData,
];

sample({
    clock: eventsTriggerPrepayment,
    source: $createAdditionalAgreementData,
    fn: (ds) =>
        getAllVirsSpecs(ds).some((el) => el.is_prepayment) &&
        !isEveryPrepaymentSpecHasDismantlingCoefficients(ds),
    target: $allowToSetPrepayment,
});

sample({
    clock: updatePrepayment,
    source: combine({
        isAllowed: $allowToSetPrepayment,
        state: $createAdditionalAgreementData,
    }),
    fn: ({isAllowed, state}, checked) => ({...state, is_prepayment: isAllowed ? checked : false}),
    target: $createAdditionalAgreementData,
});

sample({
    clock: parentSigningDateChanged,
    source: $createAdditionalAgreementData,
    filter: (_, date) => date !== '',
    fn: (source, clock) => {
        const newParentSigningDate = formatDateToBackendFormat(
            new Date(clock).valueOf() <= new Date(Date.now()).valueOf()
                ? clock
                : new Date(Date.now()).toString(),
        );
        return {...source, parent_signing_date: newParentSigningDate};
    },
    target: $createAdditionalAgreementData,
});

// выделение чекбокса аванса (работает только при создании ДС)
sample({
    clock: $allowToSetPrepayment,
    source: $createAdditionalAgreementData,
    filter: (source, clock) => source.is_prepayment !== clock && !source.id,
    fn: (source, isAllowed) => ({...source, is_prepayment: isAllowed}),
    target: $createAdditionalAgreementData,
});

sample({
    clock: setCommentToAdditionalAgreement,
    source: combine({virId: $activeAccordion, state: $createAdditionalAgreementData}),
    fn: ({state, virId}, comment) => {
        const ds = {...state};
        const pathToAgreementComment = `agreement_comments`;
        set(ds, pathToAgreementComment, [{comment}]);
        return ds;
    },
    target: $createAdditionalAgreementData,
});

sample({
    clock: setAttachmentToVir,
    source: combine({virId: $activeAccordion, state: $createAdditionalAgreementData}),
    fn: ({state, virId}, files) => {
        const ds = {...state};
        const pathToAgreementComment = `agreement_attachments`;
        set(ds, pathToAgreementComment, files);
        return ds;
    },
    target: $createAdditionalAgreementData,
});

export const createdNewAgreement = sample({
    clock: createNewAdditionalAgreement,
    source: combine({
        project: $selectedProject,
        contract: $selectedContract,
        workObject: $selectedWorkObject,
        workType: $selectedWorkType,
        tpi: $selectedTpi,
    }),
    fn: ({project, contract, workObject, workType, tpi}) => {
        const id = `front -${uuidv4()}`;
        const work_objects =
            workObject && workType
                ? {
                      ...extractWorkObjects({
                          virId: id,
                          workObject: workObject,
                          workType: workType,
                          specs: [],
                      }),
                  }
                : {};
        return {
            ...initialState,
            project_id: project?.project_id || '',
            project_number: project?.project_number,
            contract_name: contract?.contract_number,
            contract_id: contract?.framework_contract_id || '',
            tpi_id: tpi?.tpis_id || '',
            remote_territory_id: tpi?.remote_territory_id,
            begin_date: '',
            end_date: '',
            agreement_comments: [],
            agreement_attachments: [],
            works: {
                [`${id}`]: {
                    id,
                    sum: 0,
                    sum_tax: 0,
                    total_sum: 0,
                    work_objects,
                },
            },
        };
    },
});

forward({
    from: createdNewAgreement,
    to: $createAdditionalAgreementData,
});

sample({
    clock: createdNewAgreement,
    fn: (ds) => {
        const virId = Object.keys(ds.works)[0];
        const workObjectId = Object.values(ds.works[virId].work_objects)[0].object_id; // TODO tpis should be added to main part
        return {
            virId,
            workObjectId,
        };
    },
    target: $currentLocationToAddTpis,
});

$createAdditionalAgreementData.on(deleteVir, (state, virId) => {
    const ds = {...state};
    const pathToVir = getPathToVir({virId});
    unset(ds, pathToVir);
    return ds;
});

$createAdditionalAgreementData.on(createEmptyVir, (state, virId) => {
    const ds = {...state};
    set(ds, `works`, {
        ...ds.works,
        [virId]: {id: virId, sum: 0, sum_tax: 0, total_sum: 0},
    });
    return ds;
});

// Определение вида соглашения (ДС /дополнение к ДС/ расторжение ДС)
sample({
    source: $createAdditionalAgreementData,
    fn: (source) => getAgreementType(source.agreement_id, source.is_termination),
    target: $agreementType,
});
