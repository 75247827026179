import React, {useState} from 'react';
import {Checkbox, IconButton, Tooltip, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TableCell, TableCellContent} from 'shared/ui/Table/Table';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import Popover, {PopoverActionPanel, PopoverButton} from 'shared/ui/deprecated/Popover/Popover';
import {formatCurrency} from 'shared/helpers/formatHelper';
import {copyToClipboard} from 'shared/helpers/commonFunctions';
import {PROHIBITED_SPEC_DELETION_HINT} from 'shared/constants';

import * as S from './style';
import {StyledIcon, TableStringTCP} from './style';
import {TpiSpecification} from '../../types';

interface VirSelectStringProps {
    item: TpiSpecification;
    onSelected: () => void;
    checked: boolean;
    disabled?: boolean;
    onDuplicate?: (item: TpiSpecification) => void;
    isPartly?: boolean;
    pageHasNote: boolean;
    fromSelectedTable?: boolean;
}

export default function VirSelectString(props: VirSelectStringProps) {
    const {
        item,
        onSelected,
        checked,
        disabled,
        onDuplicate,
        isPartly = false,
        pageHasNote,
        fromSelectedTable = false,
    } = props;
    const [showIcon, setShowIcon] = useState<boolean>(false);

    const priceCellValue = item.is_agreed_price
        ? 'по согласованию'
        : +item.price === 0
        ? ''
        : formatCurrency(item.price.toString());
    const notSelectable = item.type_position === 1;
    const checkboxId = fromSelectedTable ? `selected_${item.number}` : item.number;
    return (
        <TableStringTCP checked={checked} notSelectable={notSelectable}>
            <TableCell>
                <TableCellContent>
                    {(fromSelectedTable || item.type_position === 2) && (
                        <Tooltip title={PROHIBITED_SPEC_DELETION_HINT}>
                            <Checkbox
                                id={checkboxId}
                                checked={checked}
                                onChange={() => onSelected()}
                                disabled={disabled}
                            />
                        </Tooltip>
                    )}
                </TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>{item.number}</TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>
                    <S.NameCell
                        needAlignmentToRight={pageHasNote && !item.note}
                        onMouseEnter={() => setShowIcon(true)}
                        onMouseLeave={() => setShowIcon(false)}
                    >
                        {!!item?.note && (
                            <Popover
                                position="top-start"
                                vertical={23}
                                horyzontal={-1}
                                component={
                                    <>
                                        <div>{item.note}</div>
                                        <PopoverActionPanel>
                                            <PopoverButton
                                                onClick={() => copyToClipboard(item?.note)}
                                            >
                                                Скопировать
                                            </PopoverButton>
                                        </PopoverActionPanel>
                                    </>
                                }
                            >
                                <StyledIcon iconName={Icons.InfoCircled} size="large" />
                            </Popover>
                        )}
                        <TooltipedSpan lineCount={2} title={item.name}>
                            <Typography variant="body3">{item.name}</Typography>
                        </TooltipedSpan>

                        <S.IconButtonWrapper>
                            {showIcon && (
                                <IconButton
                                    onClick={() => {
                                        copyToClipboard(item.name);
                                    }}
                                    iconName={Icons.Copy}
                                    size="medium"
                                />
                            )}
                        </S.IconButtonWrapper>
                    </S.NameCell>
                </TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>
                    <TooltipedSpan lineCount={2} title={item.unit}>
                        <Typography variant="body3">{item.unit}</Typography>
                    </TooltipedSpan>
                </TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>{priceCellValue}</TableCellContent>
            </TableCell>
            {onDuplicate && (
                <TableCell>
                    <TableCellContent>
                        <Tooltip title="Дублировать пункт">
                            <IconButton
                                id={`duplicate_${item.number}`}
                                iconName={Icons.Copy}
                                size="medium"
                                onClick={() => onDuplicate(item)}
                            />
                        </Tooltip>
                    </TableCellContent>
                </TableCell>
            )}
        </TableStringTCP>
    );
}
