import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {useStore} from 'effector-react';
import {Divider, IconButton, Progress, Tooltip, Typography} from '@beeline/design-system-react';

import {Icons} from '@beeline/design-tokens/js/iconfont';
import {useOutsideClick} from 'shared/hooks/useOutsideClick';
import {useWindowSize} from 'shared/hooks/useWindowSize';
import {TAttachmentFile} from 'shared/types/FileTypes';
import {StyledSpan} from 'shared/styles/commonStyle';

import * as S from './style';
import {$downloadingCardStore, downloadAllAttachments, downloadAttachment} from '../model';

interface IDownloadingCardProps {
    documentList: TAttachmentFile[];
}

export const DownloadingButtonWithCard = ({documentList}: IDownloadingCardProps) => {
    const [cardIsOpen, setCardIsOpen] = useState(false);
    const [topPoint, setTopPoint] = useState(0);
    const [leftPoint, setLeftPoint] = useState(0);
    const {downloadingFileList, isAllDownloading} = useStore($downloadingCardStore);
    const size = useWindowSize();
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        handleChangeTop();
        handleChangeLeft();
    }, [size.width, size.height]);

    const handleCloseCard = () => setCardIsOpen(false);

    const handleChangeTop = () =>
        setTopPoint((buttonRef?.current?.getBoundingClientRect().top ?? 0) + window.pageYOffset);
    const handleChangeLeft = () =>
        setLeftPoint((buttonRef?.current?.getBoundingClientRect().left ?? 0) - 330);
    const handleOpenCard = (e) => {
        e.stopPropagation();
        handleChangeTop();
        handleChangeLeft();
        setCardIsOpen(true);
    };
    const handleDownloadAttachment = (e, attachment: TAttachmentFile) => {
        e.stopPropagation();
        downloadAttachment(attachment);
    };
    const handleDownloadAll = (e) => {
        e.stopPropagation();
        downloadAllAttachments(documentList);
    };
    const ref = useOutsideClick<HTMLDivElement>(handleCloseCard);
    const fileIsLoading = (id: string) => downloadingFileList.includes(id);
    const cardPortal = createPortal(
        <S.PortalContainer>
            <S.Card
                style={{
                    margin: `${topPoint}px auto 16px ${leftPoint}px`,
                }}
                ref={ref}
                onClick={(e) => e.stopPropagation()}
            >
                <S.ItemWrapper>
                    {documentList.map((item) => (
                        <S.CardItem key={item.id}>
                            <S.SpanWrapper>
                                <Tooltip title={item.file_name}>
                                    <StyledSpan lineCount={1} noCursor typography>
                                        <Typography variant="body2">{item.file_name}</Typography>
                                    </StyledSpan>
                                </Tooltip>
                            </S.SpanWrapper>
                            {fileIsLoading(item.id) || isAllDownloading ? (
                                <Progress value={33} size="mini" shape="circle" cycled />
                            ) : (
                                <IconButton
                                    iconName={Icons.Download}
                                    size="large"
                                    onClick={(e) => handleDownloadAttachment(e, item)}
                                />
                            )}
                        </S.CardItem>
                    ))}
                </S.ItemWrapper>
                <Divider />
                <S.MarginButton variant="plain" size="medium" onClick={handleDownloadAll}>
                    Скачать все
                </S.MarginButton>
            </S.Card>
        </S.PortalContainer>,
        document.body,
    );

    return (
        <S.Wrapper>
            <Tooltip title="Скачать" disableHover={cardIsOpen}>
                <IconButton
                    ref={buttonRef}
                    iconName={'download' as Icons}
                    size="medium"
                    onClick={handleOpenCard}
                />
            </Tooltip>
            {cardIsOpen && cardPortal}
        </S.Wrapper>
    );
};
