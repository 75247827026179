import React from 'react';
import {Link} from 'react-router-dom';
import {Label, Typography} from '@beeline/design-system-react';

import {InformationalUnit} from 'Entities/InformationalUnit';
import {RoundedWrapper} from 'shared/ui/RoundedWrapper';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {IShortStatus} from 'shared/types/statusTypes';
import {LinkedTypography} from 'shared/styles/commonStyle';
import {
    CARD_TITLE,
    CREATION_DATE,
    DIADOC_LINK_TITLE,
    LINK_TITLE,
    NUMBER_TITLE,
    PO_TITLE,
    PR_TITLE,
    SIGNING_DATE,
} from 'shared/constants';

interface IntegrationWidgetProps {
    name: string;
    orderNumber?: string | null;
    date: string | null;
    status: IShortStatus;
    externalLink?: string;
    showSecondLink?: boolean;
    dcmLink?: string;
    onDownload?: () => void;
}

export const IntegrationWidget = ({
    name,
    orderNumber,
    date,
    status,
    externalLink,
    showSecondLink,
    dcmLink,
    onDownload,
}: IntegrationWidgetProps) => {
    const dateTitle = name === PR_TITLE || name === PO_TITLE ? CREATION_DATE : SIGNING_DATE;

    const handleOnDownload = () => {
        onDownload && onDownload();
    };
    return (
        <RoundedWrapper width="302px">
            <WrapperFlex justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1">{name}</Typography>
                <Label type={getLabelType(status.token)} title={status.name} />
            </WrapperFlex>
            {orderNumber && (
                <WrapperFlex gap="32px">
                    <InformationalUnit title={NUMBER_TITLE}>{orderNumber}</InformationalUnit>
                    {dcmLink && (
                        <InformationalUnit title={'Ссылка на ЕСМ'}>
                            <Link to={dcmLink} target="_blank">
                                {LINK_TITLE}
                            </Link>
                        </InformationalUnit>
                    )}
                </WrapperFlex>
            )}
            <InformationalUnit title={dateTitle}>{formatDateHours(date)}</InformationalUnit>
            {!orderNumber && externalLink && (
                <InformationalUnit title={DIADOC_LINK_TITLE}>
                    <WrapperFlex gap="16px" alignItems="center">
                        <Link to={externalLink} target="_blank">
                            {CARD_TITLE}
                        </Link>
                        {showSecondLink && (
                            <LinkedTypography variant="body3" onClick={handleOnDownload}>
                                Архив
                            </LinkedTypography>
                        )}
                    </WrapperFlex>
                </InformationalUnit>
            )}
        </RoundedWrapper>
    );
};
