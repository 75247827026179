import React from 'react';
import {useUnit} from 'effector-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {TableCell, TableCellContent, TableString} from 'shared/ui/Table/Table';
import {$userActions} from 'shared/model/user';
import {IColumnWithActions} from 'shared/types/tableTypes';
import {
    IAdditionalAgreementListItem,
    IAgreementAddition,
    IToggledAdditionalAgreementListItem,
} from 'shared/types/additionalAgreementsTypes';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

import {InnerAccordion} from '../style';

interface AgreementTableContentProps {
    columns: IColumnWithActions<IToggledAdditionalAgreementListItem>[];
    innerColumns: IColumnWithActions<IAgreementAddition>[];
    openedIds: string[];
    toggleOpenDetails: (id: string) => void;
    agreements: IAdditionalAgreementListItem[];
}

export const AgreementTableContent = ({
    columns,
    innerColumns,
    openedIds,
    agreements,
    toggleOpenDetails,
}: AgreementTableContentProps) => {
    const userActions = useUnit($userActions);
    const agreementLinkActive = userHasPermission(EAccessActions.AgreementCard, userActions);
    const handleRowClick = (id: string) =>
        agreementLinkActive
            ? navigationInvoked({
                  to: `/additional-agreements/${id}`,
              })
            : undefined;
    return (
        <>
            {agreements?.map((agreement) => (
                <React.Fragment key={agreement.id}>
                    <TableString
                        onClick={() => handleRowClick(agreement.id)}
                        clickable={agreementLinkActive}
                    >
                        {columns.map((col, idx) => (
                            <TableCell
                                key={`${col.name} ${idx}`}
                                onClick={(e) => {
                                    if (idx !== 0) {
                                        return 1;
                                    }
                                    e.stopPropagation();
                                    toggleOpenDetails(agreement.id);
                                }}
                            >
                                <TableCellContent column={col}>
                                    {col.render &&
                                        col.render(
                                            {
                                                ...agreement,
                                                isOpen: openedIds.includes(agreement.id),
                                                toggleOpenDetails,
                                            },
                                            userActions,
                                        )}
                                </TableCellContent>
                            </TableCell>
                        ))}
                    </TableString>

                    {openedIds.includes(agreement.id) &&
                        agreement.addendum?.map((additional) => (
                            <InnerAccordion key={additional.id}>
                                {innerColumns?.map((col) => (
                                    <TableCell key={`${additional.id} ${col.name}`}>
                                        <TableCellContent column={col}>
                                            {col.render && col.render(additional, userActions)}
                                        </TableCellContent>
                                    </TableCell>
                                ))}
                            </InnerAccordion>
                        ))}
                </React.Fragment>
            ))}
        </>
    );
};
