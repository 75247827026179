import React, {useState} from 'react';
import {useStore} from 'effector-react';
import {Link, useNavigate} from 'react-router-dom';
import {Button, Typography} from '@beeline/design-system-react';

import {ExecutionButtonGroup} from 'widgets/ExecutionButtonGroup';
import {
    $agreementObject,
    $buttonsDisabled,
    $tasksData,
    downloadButtonClicked,
    errorSubPageToggled,
    handleAcceptWorkflowBtn,
    handleBackendBtn,
    handleClickPositiveBtn,
    returnWorkflowBtnClicked,
    workflowButtonIndexSet,
} from 'pages/DSPage/model';
import {updateAgreement} from 'processes/contructor';
import Label from 'shared/ui/deprecated/Label/Label';
import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {certificateSignatureWindowOpened} from 'Features/CertificateSignature/model';
import ModalWindow from 'shared/ui/deprecated/ModalWindow/ModalWindow';
import {$userActions} from 'shared/model/user';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {NonMvpItem} from 'shared/styles/commonStyle';
import {EAttachmentType} from 'shared/types/FileTypes';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';
import {
    ADDITIONAL_AGREEMENT_SHORT_TITLE,
    AGREEMENT_ADDITION_TITLE,
    AGREEMENT_TERMINATION_TITLE,
    PROJECT_TITLE,
} from 'shared/constants';
import {EAccessActions} from 'shared/const/actions';

import {EditInfo, EditLink} from './style';
import {DSPageTitleSkeleton} from './DSPageTitleSkeleton';
import {AdditionalAgreementFull, ETaskTypeConfirmations} from '../../types';
import {getAgreementTitle, getModalTitle} from '../../model/helpers';

export interface DSPageTitleProps {
    agreement: AdditionalAgreementFull | null;
    agreementType: EAdditionalAgreementType | null;
    isLoading: boolean;
}

export function DSPageTitle({agreement, agreementType, isLoading}: DSPageTitleProps) {
    const userActions = useStore($userActions);
    const buttonsDisabled = useStore($buttonsDisabled);
    const taskData = useStore($tasksData);
    const agreementData = useStore($agreementObject);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [idx, setIdx] = useState<number>(-1);
    let result: Array<boolean | null> = [];
    const projectLinkActive = userHasPermission(EAccessActions.ProjectCard, userActions);
    const breadcrumbLink = `/projects/${agreement?.project}`;
    const additionalAgreementLink = `/additional-agreements/${agreement?.agreement_id}`;

    Object.values(agreementData ?? {}).forEach(
        (item) => (result = [...result, ...Object.values(item).map((obj) => obj.myApprovement)]),
    );
    const isArrayTrue = result.every((item) => item === true);
    const isArrayNull = result.some((item) => item === null);
    const isArrayFalse = result.some((item) => item === false);

    const navigate = useNavigate();

    const workflowData = taskData[0];
    const isEditable = agreement?.status?.is_editable;

    function handleEditAgreement() {
        if (agreement) {
            updateAgreement(agreement);
            navigate(`/additional-agreement/${agreement?.id}/constructor`);
        }
    }

    const titleText = getAgreementTitle(agreement, agreementType);

    const modalTitle = getModalTitle(agreementType);

    const handleClickWorkflowButton = (index: number) => {
        const {
            id,
            is_positive: isPositive,
            type_confirmation: typeConfirmation,
            header,
            description,
        } = workflowData.actions[index];

        switch (typeConfirmation) {
            case ETaskTypeConfirmations.Signing: {
                const fileId = agreement?.print_forms[0]?.id ?? '';
                certificateSignatureWindowOpened({
                    agreementId: agreement?.id ?? '',
                    id: id,
                    buttonIndex: index,
                    header: header ?? '',
                    description: description ?? '',
                    fileId: fileId,
                    fileType: agreement?.print_forms[0]?.type ?? EAttachmentType.Agreement,
                    specialFileId: agreement?.print_forms[0]?.file_id ?? '',
                });
                break;
            }
            case ETaskTypeConfirmations.ErrorCorrection: {
                workflowButtonIndexSet(index);
                errorSubPageToggled(true);
                break;
            }
            default: {
                if (isPositive) {
                    if (!isEditable && isArrayNull) {
                        setIdx(index);
                        setModalIsOpen(true);
                    } else {
                        handleClickPositiveBtn(index);
                    }
                } else {
                    handleBackendBtn(index);
                }
                break;
            }
        }
    };

    const handleConfirmModal = () => {
        handleClickPositiveBtn(idx);
        setModalIsOpen(false);
    };

    if (isLoading) {
        return <DSPageTitleSkeleton />;
    }

    return (
        <WrapperFlex
            justifyContent="space-between"
            alignItems="flex-start"
            gap="16px"
            flexWrap="wrap"
        >
            <div className="flex-grow-1">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/">Проекты</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {projectLinkActive ? (
                            <Link to={breadcrumbLink}>{`${PROJECT_TITLE} ${
                                agreement?.project_number || ''
                            }`}</Link>
                        ) : (
                            `${PROJECT_TITLE} ${agreement?.project_number || ''}`
                        )}
                    </BreadcrumbItem>
                    {agreementType === EAdditionalAgreementType.Agreement && (
                        <BreadcrumbItem>{`${ADDITIONAL_AGREEMENT_SHORT_TITLE} ${
                            agreement?.agreement_number || ''
                        }`}</BreadcrumbItem>
                    )}
                    {agreementType === EAdditionalAgreementType.Addition && (
                        <>
                            <BreadcrumbItem>
                                <Link
                                    to={additionalAgreementLink}
                                >{`${ADDITIONAL_AGREEMENT_SHORT_TITLE} ${
                                    agreement?.agreement_number || ''
                                }`}</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>{`${AGREEMENT_ADDITION_TITLE} ${agreement?.number_addendum}`}</BreadcrumbItem>
                        </>
                    )}
                    {agreementType === EAdditionalAgreementType.Termination && (
                        <>
                            <BreadcrumbItem>
                                <Link
                                    to={additionalAgreementLink}
                                >{`${ADDITIONAL_AGREEMENT_SHORT_TITLE} ${
                                    agreement?.agreement_number || ''
                                }`}</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>{AGREEMENT_TERMINATION_TITLE}</BreadcrumbItem>
                        </>
                    )}
                </Breadcrumb>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center" gap="16px">
                        <Typography variant="h4">{titleText}</Typography>
                        <Label outline variant={getLabelType(agreement?.status?.token)}>
                            {agreement?.status?.name}
                        </Label>
                    </WrapperFlex>
                </WrapperOffset>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center">
                        <EditInfo className="me-1">
                            {agreement?.editor?.full ?? ''} редактировал{' '}
                            {agreement?.updated_at &&
                                formatDateHours(agreement?.updated_at as unknown as string)}{' '}
                        </EditInfo>
                        <NonMvpItem>
                            <EditLink disabled to="#">
                                История изменений
                            </EditLink>
                        </NonMvpItem>
                    </WrapperFlex>
                </WrapperOffset>
            </div>
            {!!agreement && (
                <ExecutionButtonGroup
                    documentStatus={agreement.status}
                    taskData={taskData}
                    handleAcceptButton={handleAcceptWorkflowBtn}
                    handleDownloadButton={downloadButtonClicked}
                    handleWorkflowButton={handleClickWorkflowButton}
                    handleEditButton={handleEditAgreement}
                    handleReturnButton={returnWorkflowBtnClicked}
                    buttonsDisabled={buttonsDisabled}
                    positiveButtonDisabled={isArrayFalse}
                    negativeButtonDisabled={isArrayNull || isArrayTrue}
                />
            )}

            <ModalWindow open={modalIsOpen}>
                <WrapperFlex flexDirection="column" gap="16px">
                    <Typography variant="h5">{modalTitle}</Typography>
                    <Typography variant="body2">
                        Все неотработанные пункты будут отмечены как согласованные.
                    </Typography>
                    <WrapperFlex justifyContent="flex-end" gap="16px">
                        <Button
                            variant="outlined"
                            size="medium"
                            onClick={() => setModalIsOpen(false)}
                        >
                            Отменить
                        </Button>
                        <Button variant="contained" size="medium" onClick={handleConfirmModal}>
                            Согласовать
                        </Button>
                    </WrapperFlex>
                </WrapperFlex>
            </ModalWindow>
        </WrapperFlex>
    );
}

export {EditLink};
