import {combine, sample} from 'effector';
import {set, unset} from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {
    $tpiToConfigCoefficient,
    selectTpiToConfigureCoefficient,
} from 'pages/ApplyCoefficientsPage/model';
import {IUpdatedAgreement} from 'shared/types/additionalAgreementsTypes';

import {
    $createAdditionalAgreementData,
    deleteWorkSpecification,
    duplicateWorkSpecification,
    updateSpecAttachments,
    updateSpecCoefficient,
    updateSpecComment,
    updateSpecDate,
    updateSpecPrice,
    updateSpecQuantity,
} from './stores';
import {
    getPathToWorkObjectSpecs,
    getSpec,
    getVirWorkObjectSpecsArray,
    isEveryPrepaymentSpecHasDismantlingCoefficients,
    setUpdatesToVirSpecs,
} from '../helpers';

$createAdditionalAgreementData.on(
    duplicateWorkSpecification,
    (state, {virId, workObjectId, index, specification}) => {
        const arrayOfSpecs = getVirWorkObjectSpecsArray({
            ds: state,
            virId,
            workObjectId,
        });
        const newSpecId = `front -duplicated-${uuidv4()}`;
        const comment = specification.work_specification_comments?.[specification.id]?.comment;
        const specHasComment = (specification.work_specification_comments?.length ?? 0) > 0;
        const newSpec = {
            ...specification,
            id: newSpecId,
            selected_id: `front -duplicated-${uuidv4()}`,
            update_action: 'new',
            master_position: null,
            is_price_editable: true,
            is_quantity_editable: true,
            is_ws_deletable: true,
            work_specification_comments: specHasComment
                ? // @ts-ignore
                  [{comment: specification.work_specification_comments[0]?.comment ?? ''}]
                : [],
            ...(comment
                ? {
                      [newSpecId]: {comment},
                  }
                : {}),
        };
        const specsWithDuplicate = [
            ...arrayOfSpecs.slice(0, index + 1),
            newSpec,
            ...arrayOfSpecs.slice(index + 1),
        ].reduce((acc, curr) => ({...acc, [curr.id]: curr}), {});
        return setUpdatesToVirSpecs({
            ds: state,
            virId,
            workObjectId,
            newSpecifications: specsWithDuplicate,
        });
    },
);
$createAdditionalAgreementData.on(
    deleteWorkSpecification,
    (state, {workObjectId, virId, specId}) => {
        const ds = {...state};
        const pathToSpec = getPathToWorkObjectSpecs({virId, workObjectId, specId});
        unset(state, pathToSpec);
        return ds;
    },
);

const updateSpec = [
    updateSpecQuantity,
    updateSpecPrice,
    updateSpecCoefficient,
    updateSpecDate,
    updateSpecComment,
    updateSpecAttachments,
];

sample({
    clock: updateSpec,
    source: $createAdditionalAgreementData,
    fn: (data, {virId, workObjectId, valuesToUpdate, spec}) => {
        const ds = {...data} as IUpdatedAgreement;
        const updatedSpec = {...spec, ...valuesToUpdate};
        set(ds, getPathToWorkObjectSpecs({virId, workObjectId, specId: spec.id}), {
            ...updatedSpec,
        });
        if (isEveryPrepaymentSpecHasDismantlingCoefficients(ds)) {
            ds.is_prepayment = false;
        }
        return ds;
    },
    target: $createAdditionalAgreementData,
});

sample({
    clock: selectTpiToConfigureCoefficient,
    source: combine({
        ds: $createAdditionalAgreementData,
    }),
    fn: ({ds}, {virId, workObjectId, spec}) => {
        return {
            virId,
            workObjectId,
            spec: getSpec({ds, virId, workObjectId, specId: spec?.id}),
        };
    },
    target: $tpiToConfigCoefficient,
});
