import styled, {css} from 'styled-components';
import {Typography} from '@beeline/design-system-react';
import Select from 'shared/ui/Select/Select';

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
`;

export const TopFilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

export const JobTypeContainer = styled.div`
    height: 86px;
`;

export const ErrorMessage = styled(Typography)`
    color: var(--color-border-error);
`;

export const ChipContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const PrimeContractorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PriceContainer = styled.div`
    flex: 0 0 586px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const ModelPriceContainer = styled.div`
    flex-basis: 216px;
    height: 68px;
`;

export const BottomFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
`;

export const StyledSelect = styled(Select)<{flex?: string}>`
    ${(props) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `}
`;
