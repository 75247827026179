import React from 'react';
import {Label, Typography} from '@beeline/design-system-react';

import {InformationalUnit} from 'Entities/InformationalUnit';
import {CopyTextIconButton} from 'Features/CopyTextIconButton';
import {RoundedWrapper} from 'shared/ui/RoundedWrapper';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import {convertTokenToStatus} from 'shared/helpers/label';
import {IPaymentInfo} from 'shared/types/completedWorksTypes';
import {formatDate} from 'shared/helpers/formatHelper';
import {
    COMPLETED_WORKS_WIDGET_ACTUAL_DATE_TITLE,
    COMPLETED_WORKS_WIDGET_PLANED_DATE_TITLE,
    COMPLETED_WORKS_WIDGET_SSF_DATE_TITLE,
    COMPLETED_WORKS_WIDGET_SSF_NUMBER_TITLE,
    PAYMENT_TITLE,
    SSF_NUMBER_COPIED,
} from 'shared/constants';

interface PaymentWidgetProps {
    data: IPaymentInfo | null;
}

export const PaymentWidget = ({data}: PaymentWidgetProps) => {
    if (!data) return null;
    const {actual_payment_at, planned_payment_at, ssf_number, ssf_date, status} = data;
    return (
        <RoundedWrapper width="350px" gap="16px">
            <WrapperFlex justifyContent="space-between" gap="16px" alignItems="center">
                <Typography variant="h5">{PAYMENT_TITLE}</Typography>
                <Label type={convertTokenToStatus(status.token)} title={status.name} />
            </WrapperFlex>
            <InformationalUnit title={COMPLETED_WORKS_WIDGET_SSF_NUMBER_TITLE}>
                <WrapperFlex width="300px" noOverflow>
                    {!!ssf_number ? (
                        <WrapperFlex gap="8px" width="100%" alignItems="center">
                            <TooltipedSpan lineCount={1} title={ssf_number}>
                                <Typography variant="body2">{ssf_number}</Typography>
                            </TooltipedSpan>
                            <CopyTextIconButton
                                text={ssf_number}
                                notification={SSF_NUMBER_COPIED}
                                withTooltip
                            />
                        </WrapperFlex>
                    ) : (
                        '—'
                    )}
                </WrapperFlex>
            </InformationalUnit>
            <InformationalUnit title={COMPLETED_WORKS_WIDGET_SSF_DATE_TITLE}>
                {formatDate(ssf_date)}
            </InformationalUnit>
            <InformationalUnit title={COMPLETED_WORKS_WIDGET_PLANED_DATE_TITLE}>
                {formatDate(planned_payment_at)}
            </InformationalUnit>
            <InformationalUnit title={COMPLETED_WORKS_WIDGET_ACTUAL_DATE_TITLE}>
                {formatDate(actual_payment_at)}
            </InformationalUnit>
        </RoundedWrapper>
    );
};
