import styled, {css} from 'styled-components/macro';

export const RoundedCard = styled.div<{width: string; gap?: string}>`
    min-width: ${(props) => props.width};
    min-height: 301px;
    padding: 24px;
    flex-direction: column;
    display: flex;
    gap: 24px;
    border: 1px solid var(--color-divider-grey);
    border-radius: var(--size-border-radius-x6);
    ${(props) =>
        props.gap &&
        css`
            gap: ${props.gap};
        `}
`;
