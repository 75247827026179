import {useEffect, useRef, useState} from 'react';
import cn from 'classnames';

import {TableCell} from 'shared/ui/Table/Table';
import {useWindowSize} from 'shared/hooks/useWindowSize';
import {formatCurrency} from 'shared/helpers/formatHelper';
import {NO_TAX_TITLE, TAX_TITLE} from 'shared/constants';

import * as S from './style';

export interface SummaryProps {
    sums: {
        sum?: number;
        sum_tax?: number;
        total_sum?: number;
    };
    agreementOnRevision?: boolean;
    unclosed?: boolean;
    isTotal?: boolean;
}

export function Summary({
    sums: {sum, sum_tax, total_sum},
    agreementOnRevision,
    unclosed,
    isTotal,
}: SummaryProps) {
    const [titleWidth, setTitleWidth] = useState(0);
    const [valueWidth, setValueWidth] = useState(0);
    const ref = useRef<HTMLTableRowElement>(null);
    const size = useWindowSize();

    const firstCellId = `firstCell`;
    const coefficientCellId = `coefficientCell`;
    const priceCellId = `priceCell`;
    const firstCell = document.getElementById(firstCellId);

    useEffect(() => {
        const coefficientCell = document.getElementById(coefficientCellId);
        const priceCell = document.getElementById(priceCellId);
        const newTitleWidth =
            (coefficientCell?.getBoundingClientRect()?.right ?? 0) -
            (firstCell?.getBoundingClientRect()?.left ?? 0);
        const newValueWidth = priceCell?.offsetWidth ?? 0;
        setTitleWidth(newTitleWidth === 0 ? titleWidth : newTitleWidth);
        setValueWidth(newValueWidth === 0 ? valueWidth : newValueWidth);
    }, [ref.current?.offsetWidth, size]);

    const inactiveClassName = cn({inactiveText: unclosed});

    const hasVAT = !!sum_tax && sum_tax > 0;

    const titleWidthString = isTotal ? `${titleWidth}px` : undefined;
    const valueWidthString = isTotal ? `${valueWidth}px` : undefined;
    const colspan = isTotal ? undefined : 6;
    return (
        <>
            <S.FinalSumString ref={ref} className={inactiveClassName}>
                <TableCell colspan={colspan} width={titleWidthString}>
                    <S.Name width={titleWidthString}>{`Итого${isTotal ? ' по ВВР' : ''}${
                        unclosed ? ' осталось' : ''
                    } без НДС:`}</S.Name>
                </TableCell>
                <TableCell width={`${valueWidth}px`}>
                    <S.Value width={valueWidthString} className={inactiveClassName}>
                        {formatCurrency((sum || 0).toString())}
                    </S.Value>
                </TableCell>
                {isTotal && <TableCell />}
            </S.FinalSumString>
            <S.FinalSumString>
                <TableCell colspan={colspan}>
                    <S.Name width={titleWidthString} className={inactiveClassName}>
                        {hasVAT ? TAX_TITLE : NO_TAX_TITLE}
                    </S.Name>
                </TableCell>
                {hasVAT && (
                    <TableCell>
                        <S.Value width={valueWidthString} className={inactiveClassName}>
                            {formatCurrency((sum_tax || 0).toString())}
                        </S.Value>
                    </TableCell>
                )}
                {isTotal && <TableCell />}
            </S.FinalSumString>
            <S.FinalSumString>
                <TableCell colspan={colspan}>
                    <S.Name width={titleWidthString} className={inactiveClassName}>{`Итого${
                        unclosed ? ' осталось' : ''
                    } по ${isTotal ? 'ВВР' : 'ведомости'}${hasVAT ? ' с НДС' : ''}:`}</S.Name>
                </TableCell>
                <TableCell width="132px">
                    <S.Value width={valueWidthString} className={inactiveClassName}>
                        {formatCurrency((total_sum || 0).toString())}
                    </S.Value>
                </TableCell>
                {isTotal && <TableCell />}
            </S.FinalSumString>
        </>
    );
}
