import React from 'react';
import {TableCell, TableHead, TableString} from 'shared/ui/Table/Table';

interface HeaderRowProps {
    showAction: boolean;
}

export const HeaderRow = ({showAction}: HeaderRowProps) => {
    return (
        <TableString>
            <TableCell id="firstCell" width="56px" />
            <TableCell width="80px">
                <TableHead>№ п.п.</TableHead>
            </TableCell>
            <TableCell width="calc(100% - 927px)">
                <TableHead>Наименование работ</TableHead>
            </TableCell>
            <TableCell width="90px">
                <TableHead right>Кол-во</TableHead>
            </TableCell>
            <TableCell width="142px">
                <TableHead right>Цена за ед., ₽</TableHead>
            </TableCell>
            <TableCell id="coefficientCell" width="104px">
                <TableHead>Коэф-т</TableHead>
            </TableCell>
            <TableCell id="priceCell" width="132px">
                <TableHead right>Стоимость, ₽</TableHead>
            </TableCell>
            <TableCell width="172px">
                <TableHead>Результат</TableHead>
            </TableCell>
            <TableCell width="52px">
                <TableHead></TableHead>
            </TableCell>
            {showAction && (
                <TableCell width="102px">
                    <TableHead></TableHead>
                </TableCell>
            )}
        </TableString>
    );
};
