import React from 'react';
import {TableData, Typography} from '@beeline/design-system-react';
import {WorkObjectNameRow} from 'Entities/WorkObjectNameRow/ui/WorkObjectNameRow';
import {Summary} from 'pages/VirConstructor/Summary/Summary';
import {
    TAdaptedCompletedWorksObject,
    IAdaptedCompletedWorksSpecification,
} from 'shared/types/completedWorksTypes';
import {createAgreementWorkObjectName} from 'shared/helpers/additionalAgreements';
import {formatNumber} from 'shared/helpers/formatHelper';
import {VAT_RATE} from 'shared/const/numbers';

import {ObjectSpecificationRow} from './ObjectSpecificationRow';
import {IObjectLocation} from '../../../../model/helpers';

interface WorkObjectProps {
    touchedConstructor: boolean;
    workId: string;
    workObject: TAdaptedCompletedWorksObject;
}

export const WorkObject = ({workId, workObject, touchedConstructor}: WorkObjectProps) => {
    const {
        work_specifications: workSpecifications,
        completed_work_specifications: completedWorkSpecifications,
        finished_work_specifications: finishedWorkSpecifications,
    } = workObject;
    if (!workSpecifications) return null;

    const objectLocation: IObjectLocation = {
        worksheetId: workId,
        objectId: workObject.id,
    };

    const specificationsList: IAdaptedCompletedWorksSpecification[] = workSpecifications.filter(
        (spec) => spec.mode === 'available',
    );

    const sum = completedWorkSpecifications.reduce(
        (previousValue, currentValue) => (+currentValue.sum ?? 0) + previousValue,
        0,
    );

    const hasVAT = completedWorkSpecifications.some((spec) => +spec.sum_tax > 0);
    const taxSum = hasVAT ? formatNumber((sum * VAT_RATE) / 100) : 0;
    const totalSum = sum + taxSum;
    const completedSpecsLength = completedWorkSpecifications.length;
    const initialSpecsLength = specificationsList.length;
    const objectTitle = createAgreementWorkObjectName(workObject);
    return (
        <>
            <WorkObjectNameRow>
                <TableData colSpan={10}>
                    <Typography variant="subtitle3">{objectTitle}</Typography>
                </TableData>
            </WorkObjectNameRow>
            {completedWorkSpecifications.map((specification, index) => (
                <ObjectSpecificationRow
                    key={`${specification.id}${specification.result ?? ''}`}
                    specification={specification}
                    objectLocation={objectLocation}
                    index={index + 1}
                    disabled
                />
            ))}
            {completedWorkSpecifications.length > 0 && (
                <Summary
                    agreementOnRevision={false}
                    sums={{
                        sum: sum,
                        sum_tax: taxSum,
                        total_sum: totalSum,
                    }}
                />
            )}
            {specificationsList.map((specification, index) => (
                <ObjectSpecificationRow
                    key={`${specification.id}${specification.result ?? ''}`}
                    specification={specification}
                    objectLocation={objectLocation}
                    index={index + 1 + completedSpecsLength}
                    inactive
                />
            ))}
            {finishedWorkSpecifications.map((specification, index) => (
                <ObjectSpecificationRow
                    key={`${specification.id}${specification.result ?? ''}_finished`}
                    specification={specification}
                    objectLocation={objectLocation}
                    index={index + 1 + completedSpecsLength + initialSpecsLength}
                    finished
                    disabled
                    inactive
                />
            ))}
        </>
    );
};
