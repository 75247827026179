import React from 'react';
import {useGate, useStore} from 'effector-react';
import {Button, Skeleton, Typography} from '@beeline/design-system-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$isUserVK, $userActions} from 'shared/model/user';
import {EAccessActions} from 'shared/const/actions';

import {jobListColumns} from './columns';
import {JobTable} from './JobTable';
import {VerticalTabBlock} from '../../ProjectViewVerticalTabs/ProjectViewVerticalTabs';
import {$jobTableStore, JobTabGate} from '../../../model/jobTab';

interface JobTabProps {
    projectId?: string;
    preliminary_organization?: string[];
    projectExtId?: number;
}

export const JobTab = ({projectId, preliminary_organization, projectExtId}: JobTabProps) => {
    useGate(JobTabGate, projectId);
    const isUserVK = useStore($isUserVK);
    const actions = useStore($userActions);
    const {total, isLoading} = useStore($jobTableStore);

    const handleCreateJob = () => {
        projectId &&
            navigationInvoked({
                to: `/projects/${projectId}/job-creation`,
                options: {
                    state: {
                        preliminary_organization:
                            (preliminary_organization && preliminary_organization[0]) ?? '',
                    },
                },
            });
    };

    const createButtonVisible = isUserVK && actions.includes(EAccessActions.JobTaskCreation);
    const filteredColumns = jobListColumns.filter((column) =>
        isUserVK ? true : column.name !== 'contractor',
    );

    return (
        <VerticalTabBlock data-value={1} activeTab={1}>
            <WrapperFlex flexDirection="column" alignItems="flex-start" gap="16px">
                <Typography variant="h5">Выдача в работу</Typography>
                {createButtonVisible &&
                    (!isLoading || total > 0 ? (
                        <Button size="medium" onClick={handleCreateJob}>
                            Создать задание
                        </Button>
                    ) : (
                        <Skeleton width={169} height={48} variant="square" />
                    ))}
                <JobTable
                    createButtonVisible={createButtonVisible}
                    columns={filteredColumns}
                    handleCreateJob={handleCreateJob}
                />
            </WrapperFlex>
        </VerticalTabBlock>
    );
};
