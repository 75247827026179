import React from 'react';
import {Label} from '@beeline/design-system-react';
import {InformationalUnit} from 'Entities/InformationalUnit';

import {TypedHyperlink} from 'Features/TypedHyperlink';
import {RoundedWrapper} from 'shared/ui/RoundedWrapper';
import {IShortStatus} from 'shared/types/statusTypes';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {CONFORMING_DATE, CREATION_DATE, TERMINATION_TITLE} from 'shared/constants';
import * as S from './style';

interface AgreementAddendumCardProps {
    id: string;
    agreementId?: string | null;
    addendumNumber?: number;
    objectType: number;
    isTermination?: boolean;
    createdAt: string;
    approvedAt: string | null;
    status: IShortStatus;
}

export const AgreementAddendumCard = ({
    agreementId,
    addendumNumber,
    objectType,
    id,
    isTermination,
    createdAt,
    approvedAt,
    status,
}: AgreementAddendumCardProps) => {
    const isTerminationCondition = !!agreementId && isTermination;
    const title = isTerminationCondition ? TERMINATION_TITLE : `№ ${addendumNumber}`;
    const cardWidth = isTerminationCondition ? '280px' : '254px';
    return (
        <RoundedWrapper width={cardWidth}>
            <S.CardTitle>
                <TypedHyperlink objectType={objectType} id={id}>
                    <S.LinkedTypography variant="subtitle1">{title}</S.LinkedTypography>
                </TypedHyperlink>
                <Label type={getLabelType(status.token)} title={status.name} />
            </S.CardTitle>
            <InformationalUnit title={CREATION_DATE}>
                {formatDateHours(createdAt)}
            </InformationalUnit>
            <InformationalUnit title={CONFORMING_DATE}>
                {formatDateHours(approvedAt)}
            </InformationalUnit>
        </RoundedWrapper>
    );
};
