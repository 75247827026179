import {useState} from 'react';

import {updateSpecQuantity, calcTaxSum, calcTotal} from 'processes/contructor';
import {TpiSpecification} from 'pages/VirSelect/types';
import {getNumberMask} from 'shared/ui/deprecated/MaskInput/helpers';
import {Tooltip} from 'shared/ui/deprecated/Tooltip/Tooltip';
import {
    formatNumber,
    formatNumberToDecimalWithComma,
    formattedStringToNumber,
} from 'shared/helpers/formatHelper';
import {SET_QUANTITY_TITLE, QUANTITY_EDITING_PROHIBITION_TITLE} from 'shared/constants';
import {VAT_RATE} from 'shared/const/numbers';

import {EditableCell, StyledMaskedInput} from '../style';

interface QuantityCellProps {
    data: TpiSpecification;
    specInfo: {spec: TpiSpecification; virId: string; workObjectId: string};
    touched: boolean;
    readOnly?: boolean;
}

export function QuantityCell(props: QuantityCellProps) {
    const {data, specInfo, touched, readOnly} = props;
    const [quantity, setQuantity] = useState(formatNumberToDecimalWithComma(data.quantity));

    function handleChangeQuantity() {
        if (data?.quantity !== formattedStringToNumber(quantity)) {
            const updatedQuantity = formattedStringToNumber(quantity);
            const sum = formatNumber(
                updatedQuantity * (data?.price > 0 ? data.price : 0) * (data.coefficient || 1),
            );
            const taxRate = data.is_tax || data.sum_tax > 0 ? VAT_RATE : 0;
            updateSpecQuantity({
                ...specInfo,
                valuesToUpdate: {
                    quantity: updatedQuantity,
                    sum,
                    sum_tax: calcTaxSum(sum, taxRate),
                    total_sum: calcTotal(sum, taxRate),
                },
            });
        }
    }

    const mask = getNumberMask(data.accuracy);

    const tooltipTitle = readOnly ? QUANTITY_EDITING_PROHIBITION_TITLE : SET_QUANTITY_TITLE;
    return (
        <EditableCell error={touched && !data.quantity} isEditable={!readOnly} width="78px">
            <Tooltip titleText={tooltipTitle} position="top-end" horyzontal={0} vertical={7}>
                <StyledMaskedInput
                    mask={mask}
                    value={quantity}
                    onBlur={handleChangeQuantity}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleChangeQuantity();
                        }
                    }}
                    onAccept={setQuantity}
                    disabled={readOnly}
                />
            </Tooltip>
        </EditableCell>
    );
}
