import React from 'react';
import {useUnit} from 'effector-react';
import {TableRow} from '@beeline/design-system-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {$userActions} from 'shared/model/user';
import {CustomTableData, InnerTableRow} from 'shared/styles/commonStyle';
import {IColumnWithActions} from 'shared/types/tableTypes';
import {
    IAdditionalAgreementListItem,
    IAgreementAddition,
    IToggledAdditionalAgreementListItem,
} from 'shared/types/additionalAgreementsTypes';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

interface AgreementTableContentProps {
    columns: IColumnWithActions<IToggledAdditionalAgreementListItem>[];
    innerColumns: IColumnWithActions<IAgreementAddition>[];
    openedIds: string[];
    toggleOpenDetails: (id: string) => void;
    agreements: IAdditionalAgreementListItem[];
}

export function AgreementTableContent({
    columns,
    innerColumns,
    openedIds,
    agreements,
    toggleOpenDetails,
}: AgreementTableContentProps) {
    const userActions = useUnit($userActions);
    const hasLink = userHasPermission(EAccessActions.AgreementCard, userActions);
    const handleOnClick = (id: string) =>
        hasLink ? navigationInvoked({to: `/additional-agreements/${id}`}) : undefined;
    return (
        <>
            {agreements?.map((agreement) => (
                <React.Fragment key={agreement.id}>
                    <TableRow onClick={() => handleOnClick(agreement.id)} hover={hasLink} dense>
                        {columns?.map((col, index) => (
                            <CustomTableData
                                key={`${col.name} ${agreement.id}`}
                                width={col.width}
                                height="52px"
                                onClick={(e) => {
                                    if (index !== 0) {
                                        return 1;
                                    }
                                    e.stopPropagation();
                                    toggleOpenDetails(agreement.id);
                                }}
                                dense
                            >
                                {col.render &&
                                    col.render(
                                        {
                                            ...agreement,
                                            isOpen: openedIds.includes(agreement.id),
                                            toggleOpenDetails,
                                        },
                                        userActions,
                                    )}
                            </CustomTableData>
                        ))}
                    </TableRow>

                    {openedIds.includes(agreement.id) &&
                        agreement.addendum?.map((additional) => (
                            <InnerTableRow key={additional.id}>
                                {innerColumns?.map((col, index) => (
                                    <CustomTableData
                                        key={`${additional.id} ${col.name} ${col.name} ${index}`}
                                        height="52px"
                                        dense
                                    >
                                        {col.render && col.render(additional, userActions)}
                                    </CustomTableData>
                                ))}
                            </InnerTableRow>
                        ))}
                </React.Fragment>
            ))}
        </>
    );
}
