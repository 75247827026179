import styled, {css} from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const EmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 333px;
    border: var(--size-border-width-regular) solid var(--color-border);
    border-radius: var(--size-border-radius-x2);
`;

export const StyledTypography = styled(Typography)<{margin: string}>`
    ${(props) =>
        css`
            margin: ${props.margin};
        `}
`;
