import styled, {css} from 'styled-components/macro';

const WrapperOffsetStyledMixin = (props) => css`
    ${props.offset && `padding:${props.offset}px;`}
    ${props.offsetTop && `padding-top:${props.offsetTop}px;`}
  ${props.offsetBottom && `padding-bottom:${props.offsetBottom}px;`}
  ${props.offsetLeft && `padding-left:${props.offsetLeft}px;`}
  ${props.offsetRight && `padding-right:${props.offsetRight}px;`}
  ${props.offsetY &&
    `
		padding-top:${props.offsetY}px;
		padding-bottom:${props.offsetY}px;
	`}
  ${props.offsetX &&
    `
		padding-left:${props.offsetX}px;
		padding-right:${props.offsetX}px;
	`}
  ${props.flexGrow &&
    `
		flex: 1;
		overflow-x: auto;
	`}
  ${props.width && `width: 100%;`}
  ${props.fullWidth && `width: 100%;`}
  ${props.overflowY && `overflow-y: auto;`}
  ${props.flex &&
    `
      display: flex;
   `}
  ${props.flexColumn &&
    `
      display: flex;
      flex-direction: column;
   `}
  ${props.columnReverse &&
    `
      display: flex;
      flex-direction: column-reverse;
   `}
  ${props.gap &&
    `
      gap: ${props.gap};
   `}
`;
export const WrapperOffsetStyled = styled.div`
    ${(props) => WrapperOffsetStyledMixin(props)}
`;

const WrapperFlexStyledMixin = (props) => css`
    display: flex;
    ${props.flexDirection &&
    css`
        flex-direction: ${props.flexDirection};
    `}
    ${props.justifyContent &&
    css`
        justify-content: ${props.justifyContent};
    `}
  ${props.alignItems &&
    css`
        align-items: ${props.alignItems};
    `}
  ${props.gap &&
    css`
        gap: ${props.gap};
    `}
  ${props.flexWrap &&
    css`
        flex-wrap: ${props.flexWrap};
    `}
  ${props.flex &&
    css`
        flex: ${props.flex};
    `}
  ${props.margin &&
    css`
        margin: ${props.margin};
    `}
  ${props.padding &&
    css`
        padding: ${props.padding};
    `}
  ${props.mt &&
    css`
        margin-top: ${props.mt};
    `}
  ${props.mb &&
    css`
        margin-bottom: ${props.mb};
    `}
  ${props.ml &&
    css`
        margin-left: ${props.ml};
    `}
  ${props.mr &&
    css`
        margin-right: ${props.mr};
    `}
  ${props.minWidth &&
    css`
        min-width: ${props.minWidth};
    `}
    ${props.maxWidth &&
    css`
        max-width: ${props.maxWidth};
    `}
  ${props.width &&
    css`
        width: ${props.width};
    `}
  ${props.height &&
    css`
        height: ${props.height};
    `}
  ${props.hover &&
    css`
        &:hover {
            background-color: var(--color-background-base-hover);
        }
    `}
  ${props.alignSelf &&
    css`
        align-self: ${props.alignSelf};
    `}
  ${props.noOverflow &&
    css`
        overflow: hidden;
    `}
  ${props.verticalScroll &&
    css`
        flex: 1 0 auto;
        overflow-y: auto;
    `}
  ${props.gridArea &&
    css`
        grid-area: ${props.gridArea};
    `}
`;
export const WrapperFlexStyled = styled.div`
  ${(props) => WrapperFlexStyledMixin(props)}
`;
