import {StoreValue} from 'effector';

import {TaskCreationType} from 'shared/types/tasksTypes';
import {TAttachmentFile} from 'shared/types/FileTypes';

import {$dispatchingStore} from './stage3';

export const prepareJobForCreation = (
    data: StoreValue<typeof $dispatchingStore>,
    attachmentList: TAttachmentFile[],
): TaskCreationType => ({
    ...data,
    job_task_specifications: [...data.job_task_specifications].map((item) => {
        item.is_tax = undefined;
        return item;
    }),
    job_task_attachments: attachmentList.map((item) => ({
        id: null,
        file_id: item.id,
        history_id: item.history_id,
    })),
    remote_territory_id: data.remote_territory_id ?? undefined,
});
