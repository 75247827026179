import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {isWithinInterval} from 'date-fns';

import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';
import {fetchPoData, postPoData} from 'shared/services/completedWorks.service';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';
import {PO_NUMBER_CREATED_SNACKBAR} from 'shared/constants';

import {subpageWorkflowDone} from './index';
import {ECompletedWorksView, PoNumberCreationProps} from './helpers';
import {ETaskTypeConfirmations} from '../../DSPage/types';

const PoNumberCreationDomain = createDomain();

export const PoNumberCreationGate = createGate<PoNumberCreationProps>();

// effects
const fetchPoDataFx = PoNumberCreationDomain.effect(async (id: string) => {
    try {
        const result = await fetchPoData(id);
        return {
            poNumber: result.data.number_po,
            poIsExceptional: result.data.is_exceptional_po,
            signingDate: result.data.signing_date,
        };
    } catch (e) {
        console.warn(e);
        return null;
    }
});

const sendPoDataFx = PoNumberCreationDomain.effect(
    async ({
        id,
        poNumber,
        poIsExceptional,
        buttonIndex,
        signingDate,
    }: {
        id: string;
        poNumber: string;
        poIsExceptional: boolean;
        buttonIndex: number;
        signingDate: string;
    }) => {
        try {
            const result = await postPoData(id, poNumber, poIsExceptional, signingDate);
            return result.status === 200 ? buttonIndex : -1;
        } catch (e) {
            snackbarNotificationChanged(e);
            return -1;
        }
    },
);
// events
export const poNumberChanged = PoNumberCreationDomain.event<string>();
export const signingDateChanged = PoNumberCreationDomain.event<string>();
export const poIsExceptionalChanged = PoNumberCreationDomain.event<boolean>();
export const positiveButtonClicked = PoNumberCreationDomain.event<number>();
const workflowCompleted = PoNumberCreationDomain.event<number>();

// stores
const $poData = PoNumberCreationDomain.store<{
    poNumber: string;
    poIsExceptional: boolean;
    signingDate: string | null;
}>({
    poNumber: '',
    poIsExceptional: false,
    signingDate: null,
});
const $signingDateDisabled = PoNumberCreationDomain.store(true);
const $formTouched = PoNumberCreationDomain.store(false);
const $shouldCloseSubpage = PoNumberCreationDomain.store(false);
const $poNumberError = $poData.map((data) => data.poNumber.length === 0);
const $signingDateError = PoNumberCreationDomain.store(false);

export const $poDocumentsStore = combine({
    poData: $poData,
    poNumberError: $poNumberError,
    signingDateError: $signingDateError,
    formTouched: $formTouched,
    shouldCloseSubpage: $shouldCloseSubpage,
    signingDateDisabled: $signingDateDisabled,
    isLoading: fetchPoDataFx.pending,
    buttonDisabled: sendPoDataFx.pending,
});

// если дата пришла с бэка, то она не валидируется при отправке
sample({
    clock: combine({
        dateDisabled: $signingDateDisabled,
        data: $poData,
    }),
    fn: ({dateDisabled, data}) => {
        if (dateDisabled) return false;
        return (
            !data.signingDate ||
            (!!data.signingDate &&
                !isWithinInterval(new Date(data.signingDate), {
                    start: new Date('2020-01-01'),
                    end: new Date(Date.now()),
                }))
        );
    },
    target: $signingDateError,
});
sample({
    clock: PoNumberCreationGate.open,
    fn: (gate) => gate.id,
    target: fetchPoDataFx,
});

sample({
    clock: fetchPoDataFx.doneData,
    source: PoNumberCreationGate.state,
    filter: (gate, data) =>
        gate.mode === ECompletedWorksView.mandatoryPo || !data?.poNumber || !data?.signingDate,
    fn: (_, data) => ({
        poNumber: data?.poNumber ?? '',
        poIsExceptional: data?.poIsExceptional ?? false,
        signingDate: data?.signingDate ?? null,
    }),
    target: $poData,
});

sample({
    clock: fetchPoDataFx.doneData,
    source: PoNumberCreationGate.state,
    filter: (gate, data) =>
        gate.mode === ECompletedWorksView.po && !!data?.poNumber && !!data?.signingDate,
    fn: ({taskData, mode}, _) =>
        taskData[0]?.actions.findIndex(
            (item) =>
                item.is_positive === null &&
                item.type_confirmation ===
                    (mode === ECompletedWorksView.po
                        ? ETaskTypeConfirmations.PO
                        : ETaskTypeConfirmations.MandatoryPO),
        ) ?? -1,
    target: workflowCompleted,
});

// дисейблинг дейтпикера, если дата пришла с бэка
sample({
    clock: fetchPoDataFx.doneData,
    fn: (response) => !!response?.signingDate,
    target: $signingDateDisabled,
});

sample({
    clock: positiveButtonClicked,
    fn: () => true,
    target: $formTouched,
});

// нажатие на воркфлоу кнопку
sample({
    clock: positiveButtonClicked,
    source: combine({
        gate: PoNumberCreationGate.state,
        data: $poData,
        poNumberError: $poNumberError,
        signingDateError: $signingDateError,
    }),
    filter: ({poNumberError, signingDateError}, _) => !poNumberError && !signingDateError,
    fn: ({gate, data}, buttonIndex) => ({
        id: gate.id,
        poNumber: data.poNumber ?? '',
        poIsExceptional: data.poIsExceptional,
        buttonIndex,
        signingDate: formatDateToBackendFormat(data.signingDate ?? ''),
    }),
    target: sendPoDataFx,
});

// Успешное завершение воркфлоу страницы
sample({
    clock: sendPoDataFx.doneData,
    filter: (index) => index !== -1,
    target: workflowCompleted,
});

// вызов снека после создания номера ЗП
sample({
    clock: workflowCompleted,
    fn: () => PO_NUMBER_CREATED_SNACKBAR,
    target: snackbarNotificationChanged,
});

sample({
    clock: workflowCompleted,
    fn: () => true,
    target: $shouldCloseSubpage,
});

// передача индекса нажатой кнопки
sample({
    clock: workflowCompleted,
    target: subpageWorkflowDone,
});

$poData
    .on(poNumberChanged, (state, payload) => ({
        ...state,
        poNumber: payload.replace(/[^0-9]/g, ''),
    }))
    .on(poIsExceptionalChanged, (state, payload) => ({
        ...state,
        poIsExceptional: payload,
    }))
    .on(signingDateChanged, (state, payload) => ({...state, signingDate: payload}));

resetDomainStoresByEvents(PoNumberCreationDomain, PoNumberCreationGate.close);
