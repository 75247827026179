import {combine, createDomain, forward, sample} from 'effector';
import {createGate} from 'effector-react';

import {downloadBlobFile} from 'shared/helpers/blob';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';
import {fetchFileWithValidation} from 'shared/services/files.service';
import {TAttachmentFile} from 'shared/types/FileTypes';

export const DownloadingCardGate = createGate();
export const DownloadingCardDomain = createDomain();

export const downloadAttachment = DownloadingCardDomain.createEvent<TAttachmentFile>();
export const downloadAllAttachments = DownloadingCardDomain.createEvent<TAttachmentFile[]>();

const downloadAttachmentFx = DownloadingCardDomain.createEffect(
    async ({id, file_name, type}: TAttachmentFile) => {
        try {
            const result = await fetchFileWithValidation(id ?? '', type);
            if (result.status === 200) {
                const blob = new Blob([result.data]);
                downloadBlobFile(blob, file_name);
            }
            return id;
        } catch (e) {
            console.warn(e);
            return id;
        }
    },
);

const downloadAllAttachmentsFx = DownloadingCardDomain.createEffect(
    async (list: TAttachmentFile[]) => {
        try {
            for (let i = 0; i < list.length; i++) {
                const result = await fetchFileWithValidation(list[i].id ?? '', list[i].type);
                if (result.status === 200) {
                    const blob = new Blob([result.data]);
                    downloadBlobFile(blob, list[i].file_name);
                }
            }
        } catch (e) {
            console.warn(e);
        }
    },
);

const $downloadingFileList = DownloadingCardDomain.store<string[]>([]);

export const $downloadingCardStore = combine({
    downloadingFileList: $downloadingFileList,
    isAllDownloading: downloadAllAttachmentsFx.pending,
});

// работа со списком загружаемых файлов
sample({
    clock: downloadAttachment,
    source: $downloadingFileList,
    fn: (list, {id}) => [...list, id],
    target: $downloadingFileList,
});

sample({
    clock: downloadAttachmentFx.doneData,
    source: $downloadingFileList,
    fn: (list, id) => list.filter((item) => item !== id),
    target: $downloadingFileList,
});

forward({from: downloadAttachment, to: downloadAttachmentFx});
forward({from: downloadAllAttachments, to: downloadAllAttachmentsFx});

//сброс домена после ухода со страницы
resetDomainStoresByEvents(DownloadingCardDomain, DownloadingCardGate.close);
