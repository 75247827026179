import React from 'react';
import {IconButton, Tooltip} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {ButtonSizeVariants} from '@beeline/design-system-react/types/components/Button/Button.types';

import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';
import {COPY_BUTTON_TITLE} from 'shared/constants';

interface CopyTextIconButtonProps {
    text: string;
    notification: string;
    size?: ButtonSizeVariants;
    withTooltip?: boolean;
}

export const CopyTextIconButton = ({
    text,
    notification,
    size,
    withTooltip,
}: CopyTextIconButtonProps) => {
    const onCopy = async () => {
        try {
            navigator.clipboard.writeText(text);
            snackbarNotificationChanged(notification);
        } catch (e) {
            console.warn(e);
        }
    };

    return (
        <Tooltip title={COPY_BUTTON_TITLE} disableHover={!withTooltip}>
            <IconButton iconName={Icons.Copy} size={size} onClick={onCopy} />
        </Tooltip>
    );
};
