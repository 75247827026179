import React from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Button, Label, Skeleton, Typography} from '@beeline/design-system-react';
import {AllStatuses} from '@beeline/design-system-react/types/types/status';

import {WorkflowButtonsBlock} from 'widgets/WorkflowButtonsBlock';
import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import HeaderText from 'shared/ui/deprecated/HeaderText/HeaderText';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {NonMvpItem} from 'shared/styles/commonStyle';
import {EAccessActions} from 'shared/const/actions';

import * as S from './style';
import {EditInfo, EditLink} from '../../DSPage/ui/DSPageTitle/style';
import {
    $rawDocumentStore,
    handleAcceptWorkflowBtn, handleCancelWorkflowBtn,
    setEditMode,
} from '../model';

interface DocumentViewHeaderUnitProps {
    handleWorkflowButton: (index: number) => void;
}

export const DocumentViewHeaderUnit = ({handleWorkflowButton}: DocumentViewHeaderUnitProps) => {
    const {
        data,
        workflowButtonsStore: {workflowButtons, buttonIsLoading, availableTasks},
        userActions,
        ownerId,
    } = useStore($rawDocumentStore);

    const buttonsStatus = availableTasks[0]?.status ?? 0;
    const substageType = workflowButtons?.data.substages_type ?? 0;
    const isGroup = workflowButtons?.data.is_group ?? false;

    const isEditable =
        userActions.includes(EAccessActions.DocumentsEdit) &&
        ownerId === data?.owner_id &&
        data?.status?.is_editable &&
        data?.is_approved === null &&
        // проверка, что это именно тот пользователь, у которого есть таски
        availableTasks.length > 0;

    const isCorrectable =
        userActions.includes(EAccessActions.DocumentsEdit) &&
        ownerId === data?.owner_id &&
        data?.is_approved !== null &&
        data?.status?.is_editable &&
        data?.last_version;

    const handleAcceptWorkflowButton = () => {
        handleAcceptWorkflowBtn(availableTasks[0].id ?? '');
    };
    const handleCancelWorkflowButton = () => {
        handleCancelWorkflowBtn(availableTasks[0].id ?? '');
    };

    const handleEditDocument = () => {
        setEditMode(true);
    };

    return (
        <S.HeaderInfoWrapper>
            <div className="me-3 flex-grow-1">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/projects">Проекты</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {data ? (
                            <Link
                                to={`/projects/${data.bs.id}`}
                            >{`Проект ${data.bs.project_number}`}</Link>
                        ) : (
                            <Skeleton variant="line" width={100} height={18} />
                        )}
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {data ? (
                            `Документ ${data.number}`
                        ) : (
                            <Skeleton variant="line" width={100} height={18} />
                        )}
                    </BreadcrumbItem>
                </Breadcrumb>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center">
                        <HeaderText className="me-2" component="h4">
                            {data ? (
                                `Документ ${data.number}${
                                    data.organization_gpo ? ` ${data.organization_gpo}` : ''
                                }`
                            ) : (
                                <Skeleton variant="square" width={300} height={32} />
                            )}
                        </HeaderText>
                        {data ? (
                            data.status && (
                                <Label
                                    title={data.status.name}
                                    type={getLabelType(data.status.token) as AllStatuses}
                                />
                            )
                        ) : (
                            <Skeleton variant="square" width={120} height={32} />
                        )}
                    </WrapperFlex>
                </WrapperOffset>
                <WrapperOffset offsetTop={12}>
                    {data ? (
                        <Typography variant="body2">{data.subtypes_document}</Typography>
                    ) : (
                        <Skeleton variant="line" width={180} height={22} />
                    )}
                </WrapperOffset>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center">
                        <EditInfo className="me-1">
                            {data && data.creator && data.created_at ? (
                                `Создал ${data.creator.short}, редактировал ${
                                    data.editor.short
                                } ${formatDateHours(data.updated_at.toString())}`
                            ) : (
                                <Skeleton variant="line" width={180} height={16} />
                            )}
                        </EditInfo>
                        <NonMvpItem>
                            <EditLink disabled to="#">
                                История изменений
                            </EditLink>
                        </NonMvpItem>
                    </WrapperFlex>
                </WrapperOffset>
            </div>
            {isEditable || isCorrectable ? (
                <Button variant="contained" size="medium" onClick={handleEditDocument}>
                    Редактировать
                </Button>
            ) : (
                <WorkflowButtonsBlock
                    workflowButtons={workflowButtons}
                    buttonIsLoading={buttonIsLoading}
                    status={buttonsStatus}
                    substageType={substageType}
                    handleCancelTask={handleCancelWorkflowButton}
                    isGroup={isGroup}
                    handleWorkflowButton={handleWorkflowButton}
                    handleAcceptWorkflowButton={handleAcceptWorkflowButton}
                />
            )}
        </S.HeaderInfoWrapper>
    );
};
