import React from 'react';
import {Link} from 'react-router-dom';
import {Label, Typography} from '@beeline/design-system-react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {InformationalUnit} from 'Entities/InformationalUnit';
import {RoundedWrapper} from 'shared/ui/RoundedWrapper';
import {IEdoObject} from 'shared/types/completedWorksTypes';
import {
    CREATION_DATE,
    DIADOC_LINKS_TITLE,
    DOWNLOAD_TITLE,
    INVOICE_TITLE,
    KS2_TITLE,
    KS3_TITLE,
} from 'shared/constants';
import {convertTokenToStatus} from 'shared/helpers/label';

interface EDMSWidgetProps {
    data: IEdoObject | null;
    title: string;
}

export const EdmsWidget = ({data, title}: EDMSWidgetProps) => {
    if (!data) return null;
    const {
        status,
        diadoc_url_ks_2,
        diadoc_url_ks_3,
        diadoc_url_sf,
        download_ks_2,
        download_sf,
        download_ks_3,
        ks2_created_at,
    } = data;

    const hasLinkUnit = diadoc_url_ks_2 || diadoc_url_ks_3 || diadoc_url_sf;
    const hasDownloadUnit = download_ks_2 || download_sf || download_ks_3;

    return (
        <RoundedWrapper width="350px" gap="16px">
            <WrapperFlex justifyContent="space-between" gap="16px" alignItems="center">
                <Typography variant="h5">{title}</Typography>
                <Label type={convertTokenToStatus(status.token)} title={status.name} />
            </WrapperFlex>
            <InformationalUnit title={CREATION_DATE}>{ks2_created_at}</InformationalUnit>
            {hasLinkUnit && (
                <InformationalUnit title={DIADOC_LINKS_TITLE}>
                    <WrapperFlex gap="32px">
                        {diadoc_url_ks_2 && (
                            <Link to={diadoc_url_ks_2} target="_blank">
                                {KS2_TITLE}
                            </Link>
                        )}
                        {diadoc_url_ks_3 && (
                            <Link to={diadoc_url_ks_3} target="_blank">
                                {KS3_TITLE}
                            </Link>
                        )}
                        {diadoc_url_sf && (
                            <Link to={diadoc_url_sf} target="_blank">
                                {INVOICE_TITLE}
                            </Link>
                        )}
                    </WrapperFlex>
                </InformationalUnit>
            )}
            {hasDownloadUnit && (
                <InformationalUnit title={DOWNLOAD_TITLE}>
                    <WrapperFlex alignItems="center" gap="32px">
                        {download_ks_2 && (
                            <Link to={download_ks_2} target="_blank">
                                {KS2_TITLE}
                            </Link>
                        )}
                        {download_ks_3 && (
                            <Link to={download_ks_3} target="_blank">
                                {KS3_TITLE}
                            </Link>
                        )}
                        {download_sf && (
                            <Link to={download_sf} target="_blank">
                                {INVOICE_TITLE}
                            </Link>
                        )}
                    </WrapperFlex>
                </InformationalUnit>
            )}
        </RoundedWrapper>
    );
};
