import {combine, forward, sample} from 'effector';
import {createGate} from 'effector-react';

import {
    getPurchasingEventsInfo,
    getPurchasingEventTypes,
    getTaskType,
} from 'shared/services/jobs.service';
import {
    BiddingParticipationsRequestType,
    BiddingParticipationTypeForStage1,
    PurchasingEventInfoType,
    PurchasingEventType,
    PurchasingEventTypesResponse,
    TPurchasingEventInfoForStage1,
} from 'shared/types/purchasesTypes';
import {forwardPayload} from 'shared/helpers/effector';
import {JobTaskTypesType} from 'shared/services/types/configuratorTypes';
import {$projectId, creationJobProcessDomain} from './index';
import {TTpiWithRemoteTerritory} from 'shared/types/tpiTypes';

export const jobProcessDomainStage1 = creationJobProcessDomain.domain();
export const jobProcessGateStage1 = createGate();

//Events
export const getPurchasingTypes = jobProcessDomainStage1.createEvent();
export const getPrimeContractorsData = jobProcessDomainStage1.createEvent();
export const getJobTaskTypes = jobProcessDomainStage1.createEvent();
export const setPrimeContractorsDataCount = jobProcessDomainStage1.createEvent<number>();
export const setPage = jobProcessDomainStage1.createEvent<number>();
export const setPageSize = jobProcessDomainStage1.createEvent<number>();
export const setSelectedEventType = jobProcessDomainStage1.createEvent<PurchasingEventType>();
export const setSearchValue = jobProcessDomainStage1.createEvent<string>();
export const resetSearchValue = jobProcessDomainStage1.createEvent();
export const setSelectedJobTaskType = jobProcessDomainStage1.createEvent<string>();
export const setButtonsDisabled = jobProcessDomainStage1.createEvent<boolean>();
export const setModelCostError = jobProcessDomainStage1.createEvent<boolean>();
export const setJobTaskTypeError = jobProcessDomainStage1.createEvent<boolean>();
export const setTpiSelectError = jobProcessDomainStage1.createEvent<string | null>();
export const setModelCost = jobProcessDomainStage1.createEvent<string>();
export const prepareStage1Data = jobProcessDomainStage1.createEvent<string>();
export const setSelectedTpiData =
    jobProcessDomainStage1.createEvent<Record<string, TTpiWithRemoteTerritory>>();

//Effects
const fetchPurchasingEventTypesFx = jobProcessDomainStage1.createEffect(async () => {
    try {
        const result = await getPurchasingEventTypes();
        return result.data;
    } catch (e) {
        console.error(e);
    }
});

const fetchPrimeContractorsDataFx = jobProcessDomainStage1.createEffect(
    async (params: BiddingParticipationsRequestType) => {
        try {
            const result = await getPurchasingEventsInfo(params);
            setPrimeContractorsDataCount(result.data.count);
            return result.data.results;
        } catch (e) {
            console.error(e);
        }
    },
);

const fetchJobTaskTypesFx = jobProcessDomainStage1.createEffect(async () => {
    try {
        const result = await getTaskType(true);
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

//Stores
export const $page = jobProcessDomainStage1
    .createStore<number>(1)
    .on(setPage, forwardPayload())
    .reset([setPageSize, setSearchValue, setSelectedEventType]);
export const $pageSize = jobProcessDomainStage1
    .createStore<number>(10)
    .on(setPageSize, forwardPayload());

export const $purchasingEventTypesList = jobProcessDomainStage1
    .createStore<PurchasingEventTypesResponse>([])
    .on(fetchPurchasingEventTypesFx.doneData, forwardPayload());

export const $selectedEventType = jobProcessDomainStage1
    .createStore<PurchasingEventType | null>(null)
    .on(setSelectedEventType, (state, payload) => {
        return state?.id && state.id === payload.id ? null : payload;
    });

export const $primeContractorsData = jobProcessDomainStage1
    .createStore<Array<TPurchasingEventInfoForStage1> | null>(null)
    .on(fetchPrimeContractorsDataFx.doneData, (state, payload) => {
        const result = payload?.map((item) => {
            const tempObj: BiddingParticipationTypeForStage1 = {
                ...item.bidding_participation,
                tpiList: [],
            };

            const result: TTpiWithRemoteTerritory[] = [];
            item.bidding_participation.tpis.forEach((tpiItem) => {
                const resultItem: TTpiWithRemoteTerritory = {};
                resultItem.id = tpiItem.id;
                resultItem.name = tpiItem.name;
                if ((tpiItem.remote_territories?.length ?? -1) > 0) {
                    tpiItem.remote_territories?.forEach((rtItem) => {
                        const resultItemWithRemote: TTpiWithRemoteTerritory = {};
                        resultItemWithRemote.id = tpiItem.id;
                        resultItemWithRemote.name = `${resultItem.name} (${rtItem.name})`;
                        resultItemWithRemote.remote_territory_id = rtItem.id;
                        result.push(resultItemWithRemote);
                    });
                } else {
                    result.push(resultItem);
                }
            });
            tempObj.tpiList = result;
            return {...item, bidding_participation: tempObj};
        });
        return result;
    });

export const $primeContractorsDataCount = jobProcessDomainStage1
    .createStore<number>(0)
    .on(setPrimeContractorsDataCount, forwardPayload());

export const $taskTypesData = jobProcessDomainStage1
    .createStore<JobTaskTypesType[]>([])
    .on(fetchJobTaskTypesFx.doneData, forwardPayload());

export const $searchValue = jobProcessDomainStage1
    .createStore<string>('')
    .on(setSearchValue, forwardPayload())
    .reset(resetSearchValue);

export const $SelectedJobTaskType = jobProcessDomainStage1
    .createStore<string>('0')
    .on(setSelectedJobTaskType, forwardPayload());

export const $jobTypeError = jobProcessDomainStage1
    .createStore<boolean>(false)
    .on(setJobTaskTypeError, forwardPayload());

export const $tpiSelectError = jobProcessDomainStage1
    .createStore<string | null>(null)
    .on(setTpiSelectError, forwardPayload());

export const $SelectedJobTaskId = jobProcessDomainStage1
    .createStore<string>('')
    .on(fetchJobTaskTypesFx.doneData, (state, payload) => {
        return payload?.find((item) => item.code === '1')?.id ?? '';
    });

export const $buttonsDisabled = jobProcessDomainStage1
    .createStore<boolean>(false)
    .on(setButtonsDisabled, forwardPayload());

export const $modelCost = jobProcessDomainStage1
    .createStore<string>('')
    .on(setModelCost, forwardPayload());

export const $modelCostError = jobProcessDomainStage1
    .createStore<boolean>(false)
    .on(setModelCostError, forwardPayload());

export const $selectedContractorId = jobProcessDomainStage1.createStore<string>('');
export const $selectedPurchasingEventId = jobProcessDomainStage1.createStore<string>('');
export const $selectedPurchasingEventInfo = jobProcessDomainStage1.createStore<string>('');
export const $selectedJobTypeName = jobProcessDomainStage1.createStore<string>('');
export const $selectedBiddingParticipationId = jobProcessDomainStage1.createStore<string>('');
export const $selectedContractId = jobProcessDomainStage1.createStore<string>('');
export const $selectedPrimeContractorName = jobProcessDomainStage1.createStore<string>('');

export const $selectedPrimeContractorData =
    jobProcessDomainStage1.createStore<PurchasingEventInfoType | null>(null);

export const $selectedTpiData = jobProcessDomainStage1.createStore<
    Record<string, TTpiWithRemoteTerritory>
>({});

export const $errors = combine({
    modelCostError: $modelCostError,
    jobTypeError: $jobTypeError,
    tpiSelectError: $tpiSelectError,
});

export const $jobCreationPageStore = combine({
    primeContractorsData: $primeContractorsData,
    selectedTpiData: $selectedTpiData,
    primeContractorsDataIsLoading: fetchPrimeContractorsDataFx.pending,
    count: $primeContractorsDataCount,
    page: $page,
    pageSize: $pageSize,
    taskTypes: $taskTypesData,
    selectedEventType: $selectedEventType,
    selectedJobTaskType: $SelectedJobTaskType,
    modelCost: $modelCost,
    buttonsDisabled: $buttonsDisabled,
    searchValue: $searchValue,
    errors: $errors,
});

export const $refetchingPrimeContractorsStore = combine({
    page: $page,
    pageSize: $pageSize,
    selectedType: $selectedEventType,
    search: $searchValue,
    project: $projectId,
});

//открытие страницы
forward({
    from: jobProcessGateStage1.open,
    to: [getPurchasingTypes, getPrimeContractorsData, getJobTaskTypes],
});
forward({from: getPurchasingTypes, to: fetchPurchasingEventTypesFx});
forward({from: getJobTaskTypes, to: fetchJobTaskTypesFx});

//Fetch и refetch при изменении зависимых данных
sample({
    clock: [$refetchingPrimeContractorsStore, getPrimeContractorsData],
    source: $refetchingPrimeContractorsStore,
    filter: (store) => !!store.project,
    fn: (store) => ({
        purchasing_event_type: store.selectedType?.id ?? '',
        search: store.search,
        page: store.page,
        page_size: store.pageSize,
        project: store.project,
    }),
    target: fetchPrimeContractorsDataFx,
});

//запись ID выбранного задания, при его выборе
sample({
    clock: $SelectedJobTaskType,
    source: $taskTypesData,
    fn: (source, clock) => {
        return source?.find((item) => item.code === clock)?.id ?? '';
    },
    target: $SelectedJobTaskId,
});

sample({
    clock: $SelectedJobTaskType,
    source: $taskTypesData,
    fn: (source, clock) => {
        return source?.find((item) => item.code === clock)?.name ?? '';
    },
    target: $selectedJobTypeName,
});

// Подготовка полей для создания задания
sample({
    clock: prepareStage1Data,
    source: $primeContractorsData,
    fn: (source, clock) => source?.find((item) => item.bidding_participation.id === clock) ?? null,
    target: $selectedPrimeContractorData,
});

sample({
    source: $selectedPrimeContractorData,
    fn: (source) => source?.bidding_participation.organization_id ?? '',
    target: $selectedContractorId,
});

sample({
    source: $selectedPrimeContractorData,
    fn: (source) => source?.purchasing_event?.id ?? '',
    target: $selectedPurchasingEventId,
});

sample({
    source: $selectedPrimeContractorData,
    fn: (source) => source?.bidding_participation.id ?? '',
    target: $selectedBiddingParticipationId,
});

sample({
    source: $selectedPrimeContractorData,
    fn: (source) => source?.contract.id ?? '',
    target: $selectedContractId,
});

sample({
    source: $selectedPrimeContractorData,
    fn: (source) =>
        `${source?.purchasing_event?.number ?? ''} - ${source?.purchasing_event?.type ?? ''}`,
    target: $selectedPurchasingEventInfo,
});

sample({
    source: $selectedPrimeContractorData,
    fn: (source) => source?.bidding_participation.organization_short_name ?? '',
    target: $selectedPrimeContractorName,
});

//Обработка ошибок
//блокирует кнопки "выбрать", если не заполнена "модельная стомость", "Тип Заданий" или не выбран "ТЦП"@s

sample({
    source: $errors,
    fn: (source) => source.modelCostError || source.jobTypeError || !!source.tpiSelectError,
    target: $buttonsDisabled,
});

sample({
    source: $SelectedJobTaskType,
    fn: (source) => source === '0',
    target: $jobTypeError,
});

sample({
    source: $modelCost,
    fn: (source) => source === '',
    target: $modelCostError,
});

sample({
    clock: setSelectedTpiData,
    source: $selectedTpiData,
    filter: (_, clock) => Object.keys(clock).length > 0,
    fn: (source, clock) => {
        const property = Object.keys(clock)[0];
        const result = {...source};
        result[property] = clock[property];
        return result;
    },
    target: $selectedTpiData,
});

// сброс ошибки селекта ТЦП при выборе любого пункта в любом селекте ТЦП
sample({
    clock: setSelectedTpiData,
    fn: () => null,
    target: setTpiSelectError,
});

// автовыбор селектов, если с бэка пришло одно значение ТЦП
sample({
    source: $primeContractorsData,
    filter: (source) => (source?.length ?? -1) > 0,
    fn: (source) => {
        const result: Record<string, TTpiWithRemoteTerritory> = {};
        source?.forEach((item) => {
            if (item.bidding_participation.tpiList.length === 1) {
                result[item.bidding_participation.id] = item.bidding_participation.tpiList[0];
            }
        });
        return result;
    },
    target: $selectedTpiData,
});
