import {useGate, useStore} from 'effector-react';
import {Checkbox} from '@beeline/design-system-react';

import VirSelectString from 'pages/VirSelect/ui/TpiRow/VirSelectInfoString';
import {VirList} from 'pages/VirSelect/ui/TpiRow/style';
import {SELECT_TPIS_ACTIVE_TAB, TpiSpecification} from 'pages/VirSelect/types';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {Table, TableCell, TableHead, TableString} from 'shared/ui/Table/Table';
import Pagination from 'shared/ui/Pagination/Pagination';
import {TabsBlock} from 'shared/ui/deprecated/Tabs/Tabs';

import {
    $selectedPaginationParams,
    $virSelectAllTpisTab,
    duplicatePoint,
    SelectedTpisGate,
    setPage,
    setPageSize,
    unselectAll,
    unselectPoint,
} from '../../model/SelectedTpisTab';
import {$activeTab} from '../../model/SelectTpisPage';
import {isItemDisabled} from '../../model/helpers';

export function SelectedTpisTab() {
    useGate(SelectedTpisGate);
    const {count, page, pageSize} = useStore($selectedPaginationParams);
    const {selectedTpisOnPage, selectedTpis} = useStore($virSelectAllTpisTab);
    const activeTab = useStore($activeTab);
    const allSelectedChecked = selectedTpisOnPage.length === selectedTpis.length;
    const isPartly = !!selectedTpis.length && !allSelectedChecked;
    const isCheckedItem = (item: TpiSpecification) => selectedTpis.some((el) => el.id === item.id);
    return (
        <TabsBlock value={SELECT_TPIS_ACTIVE_TAB.SELECTED_TPIS} activeTab={activeTab}>
            {selectedTpisOnPage.length ? (
                <VirList>
                    <Table>
                        <tbody>
                            <TableString>
                                <TableCell width="56px">
                                    <TableHead>
                                        <Checkbox
                                            id="allSelectedTpiItemsCheckbox"
                                            onChange={() => unselectAll()}
                                            type={isPartly ? 'indeterminate' : 'checkbox'}
                                            checked={selectedTpis.length > 0}
                                        />
                                    </TableHead>
                                </TableCell>
                                <TableCell width="80px">
                                    <TableHead>№ п.п</TableHead>
                                </TableCell>
                                <TableCell width="calc(100% - 589px);">
                                    <TableHead>Наименование работ</TableHead>
                                </TableCell>
                                <TableCell width="167px">
                                    <TableHead>Ед. измерения</TableHead>
                                </TableCell>
                                <TableCell width="123px">
                                    <TableHead>Цена за ед., ₽</TableHead>
                                </TableCell>
                                <TableCell width="56px" />
                            </TableString>
                            {selectedTpisOnPage.length &&
                                selectedTpisOnPage.map((item, index, array) => (
                                    <VirSelectString
                                        key={item.id}
                                        pageHasNote={array.some((el) => el.note)}
                                        item={item}
                                        checked={isCheckedItem(item)}
                                        onSelected={() => unselectPoint(item)}
                                        onDuplicate={() => duplicatePoint(item)}
                                        disabled={isItemDisabled(item, selectedTpis)}
                                        fromSelectedTable
                                    />
                                ))}
                        </tbody>
                    </Table>
                    <WrapperOffset offsetY="13" offsetX="16">
                        <Pagination
                            total={count}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                        />
                    </WrapperOffset>
                </VirList>
            ) : (
                <NotFound>
                    <NotFoundTitle>Нет выбранных пунктов работ</NotFoundTitle>
                    <NotFoundCaption>Вы можете добавить их на вкладке «Все пункты»</NotFoundCaption>
                </NotFound>
            )}
        </TabsBlock>
    );
}
