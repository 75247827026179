import React from 'react';
import {useStore} from 'effector-react';
import {Button, Icon, TableBody, TableRow} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {TableHeader} from 'Features/TableHeader';
import {TableBase} from 'Entities/TableBase';
import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';
import {$userActions} from 'shared/model/user';
import {TJobListItem} from 'shared/types/jobsTypes';
import {IColumnWithActions} from 'shared/types/tableTypes';
import {IPaginationData} from 'shared/types/commonTypes';
import {CustomTableData} from 'shared/styles/commonStyle';

import * as S from './style';
import {$jobTableStore, jobPageChanged, jobPageSizeChanged} from '../../../model/jobTab';
import {userHasPermission} from '../../../../../shared/helpers/accessCheckers';
import {EAccessActions} from '../../../../../shared/const/actions';

interface JobTableProps {
    createButtonVisible: boolean;
    columns: IColumnWithActions<TJobListItem>[];
    handleCreateJob: () => void;
}

export const JobTable = ({columns, createButtonVisible, handleCreateJob}: JobTableProps) => {
    const actions = useStore($userActions);
    const {params, total, tableData, isLoading} = useStore($jobTableStore);
    const tableIsEmpty = !isLoading && total === 0;
    const hasLink = userHasPermission(EAccessActions.JobTaskCard, actions);
    const paginationData: IPaginationData = {
        ...params,
        total,
        setPage: jobPageChanged,
        setPageSize: jobPageSizeChanged,
        isLoading,
    };

    const handleNavigate = (id: string) =>
        hasLink
            ? navigationInvoked({
                  to: `/jobs/${id}`,
              })
            : undefined;

    return tableIsEmpty ? (
        <S.EmptyWrapper>
            <Icon iconName={Icons.PageEmpty} color="blue" size="medium" contained />
            <S.StyledTypography variant="h6" margin="24px 0 8px">
                Выданных заданий еще нет
            </S.StyledTypography>
            {createButtonVisible && (
                <>
                    <S.StyledTypography variant="body2" className="inactiveText" margin="0 0 24px">
                        Вы можете создать его, нажав на кнопку ниже
                    </S.StyledTypography>
                    <Button className="mb-3" size="medium" onClick={handleCreateJob}>
                        Создать задание
                    </Button>
                </>
            )}
        </S.EmptyWrapper>
    ) : (
        <TableBase minTableWidth="1050px" paginationData={paginationData}>
            <TableHeader<TJobListItem> columns={columns} />
            {isLoading ? (
                <TableBodySkeleton<TJobListItem>
                    pageSize={params.pageSize}
                    columns={columns}
                    height="52px"
                    dense
                />
            ) : (
                <TableBody>
                    {tableData.map((item) => (
                        <TableRow key={item.id} onClick={() => handleNavigate(item.id)} hover={hasLink}>
                            {columns.map((column) => (
                                <CustomTableData
                                    key={`${item.id}_${column.name}`}
                                    width={column.width}
                                    height="52px"
                                    dense
                                >
                                    {column.render && column.render(item, actions)}
                                </CustomTableData>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            )}
        </TableBase>
    );
};
