import {combine, sample} from 'effector';
import {createGate} from 'effector-react';

import {forwardPayload} from 'shared/helpers/effector';
import {postNewTask} from 'shared/services/jobs.service';
import {NriRllFileType, TpiSpecificationOutputType} from 'shared/types/jobsTypes';
import {TAttachmentFile} from 'shared/types/FileTypes';
import {
    fetchFileWithValidation,
    fetchNriBsFile,
    fetchNriRllFile,
    fetchNriRllList,
    postJobAttachment,
} from 'shared/services/files.service';
import {downloadBlobFile} from 'shared/helpers/blob';

import {$projectId, $tpiStore, creationJobProcessDomain} from './index';
import {
    $modelCost,
    $selectedBiddingParticipationId,
    $selectedContractId,
    $selectedContractorId,
    $SelectedJobTaskId,
    $selectedPurchasingEventId,
} from './stage1';
import {$selectedTpiData} from './stage2';
import {prepareJobForCreation} from './helpers';

export const jobProcessDomainStage3 = creationJobProcessDomain.domain();
export const jobProcessGateStage3 = createGate();

//events
export const increaseDeadline = jobProcessDomainStage3.createEvent();
export const decreaseDeadline = jobProcessDomainStage3.createEvent();
export const setComment = jobProcessDomainStage3.createEvent<string>();
export const recalculateJobTaskSpecifications = jobProcessDomainStage3.createEvent();
export const setQuantity = jobProcessDomainStage3.createEvent<{id: string; value: number}>();
export const deleteRow = jobProcessDomainStage3.createEvent<string>();
export const createNewTask = jobProcessDomainStage3.createEvent();
export const downloadNriBsFile = jobProcessDomainStage3.createEvent<string>();
export const downloadNriRllFile = jobProcessDomainStage3.createEvent<{
    id: number;
    fileName: string;
    mimeType: string;
}>();
export const attachFile = jobProcessDomainStage3.createEvent<FileList>();
export const downloadAttachmentFile = jobProcessDomainStage3.createEvent<TAttachmentFile>();

//effects
export const createNewTaskFx = jobProcessDomainStage3.createEffect(async (data) => {
    try {
        return await postNewTask(data);
    } catch (e) {
        console.log(e);
    }
});

export const downloadNriBsFileFx = jobProcessDomainStage3.createEffect(
    async (projectId: string) => {
        try {
            const result = await fetchNriBsFile(projectId);
            if (result.status === 200) {
                const blob = new Blob([result.data], {
                    type: result.headers['content-type'],
                });
                const fileName =
                    result.request
                        .getResponseHeader('content-disposition')
                        .split('filename="')[1]
                        .split('"')[0] ?? 'Исходные данные по БС';
                downloadBlobFile(blob, fileName);
            }
        } catch (e) {
            console.log(e);
        }
    },
);

export const downloadNriRllFileFx = jobProcessDomainStage3.createEffect(
    async ({id, fileName, mimeType}: {id: number; fileName: string; mimeType: string}) => {
        try {
            const result = await fetchNriRllFile(id);
            if (result.status === 200) {
                const blob = new Blob([result.data], {
                    type: mimeType,
                });
                downloadBlobFile(blob, fileName);
            }
        } catch (e) {
            console.log(e);
        }
    },
);

export const getNriRllFilesListFx = jobProcessDomainStage3.createEffect(
    async (projectExtId: string) => {
        try {
            const result = await fetchNriRllList(projectExtId);
            return result.data;
        } catch (e) {
            console.log(e);
        }
    },
);

export const sendAttachmentFileFx = jobProcessDomainStage3.createEffect(async (file: File) => {
    try {
        const result = await postJobAttachment(file, undefined, file.name);
        if (result.status === 201) {
            return result.data.ids;
        }
    } catch (e) {
        console.log(e);
    }
});

export const downloadAttachmentFileFx = jobProcessDomainStage3.createEffect(
    async ({id, file_name, type}: TAttachmentFile) => {
        try {
            const result = await fetchFileWithValidation(id, type);
            if (result.status === 200) {
                const blob = new Blob([result.data]);
                downloadBlobFile(blob, file_name);
            }
        } catch (e) {
            console.log(e);
        }
    },
);

//stores
export const $jobTaskSpecifications = jobProcessDomainStage3.createStore<
    TpiSpecificationOutputType[]
>([]);
export const $deadlineCount = jobProcessDomainStage3
    .createStore<number>(10)
    .on(increaseDeadline, (state, _) => state + 1)
    .on(decreaseDeadline, (state, _) => state - 1);
export const $comment = jobProcessDomainStage3
    .createStore<string>('')
    .on(setComment, forwardPayload());
export const $totalSum = jobProcessDomainStage3.createStore<number>(0);
export const $totalTax = jobProcessDomainStage3.createStore<number>(0);
export const $totalSumWithTax = jobProcessDomainStage3.createStore<number>(0);
export const $createdJobId = jobProcessDomainStage3.createStore<string | null>(null);
export const $successfulJobCreation = jobProcessDomainStage3.createStore<boolean>(false);
export const $NriRllFilesList = jobProcessDomainStage3
    .createStore<NriRllFileType[]>([])
    .on(getNriRllFilesListFx.doneData, forwardPayload());

export const $attachmentFilesList = jobProcessDomainStage3
    .createStore<TAttachmentFile[]>([])
    .on(sendAttachmentFileFx.doneData, (state, payload) => {
        return payload ? [...state, payload[0]] : state;
    });

export const $buttonsState = combine({
    bsButtonIsLoading: downloadNriBsFileFx.pending,
    rllButtonIsLoading: downloadNriRllFileFx.pending,
    attachmentButtonIsLoading: downloadAttachmentFileFx.pending,
    buttonsAreDisabled:
        downloadNriBsFileFx.pending ||
        downloadNriRllFileFx.pending ||
        downloadAttachmentFileFx.pending,
});

export const $stage3Store = combine({
    jobTaskSpecifications: $jobTaskSpecifications,
    deadlineCount: $deadlineCount,
    comment: $comment,
    totalSum: $totalSum,
    createdJobId: $createdJobId,
    successfulJobCreation: $successfulJobCreation,
    submitButtonIsDisabled: createNewTaskFx.pending,
    buttonsState: $buttonsState,
    nriRllFilesList: $NriRllFilesList,
    nriRllFilesListIsLoading: getNriRllFilesListFx.pending,
    attachmentFilesList: $attachmentFilesList,
});

export const $dispatchingStore = combine({
    is_released: false,
    is_approved: false,
    project_id: $projectId,
    job_task_type_id: $SelectedJobTaskId,
    contract_id: $selectedContractId,
    contractor_id: $selectedContractorId,
    tpi_id: $tpiStore.map((state) => state.tpi_id),
    remote_territory_id: $tpiStore.map((state) => state.remote_territory_id),
    purchasing_event_id: $selectedPurchasingEventId,
    bidding_participation_id: $selectedBiddingParticipationId,
    model_cost: $modelCost,
    sum: $totalSum,
    sum_tax: $totalTax,
    total_sum: $totalSumWithTax,
    period_execution: $deadlineCount,
    job_task_specifications: $jobTaskSpecifications,
    job_task_comment: $comment,
});

// Запрос на получение списка файлов из NRI при открытии 3 шага
sample({
    clock: jobProcessGateStage3.open,
    source: $projectId,
    target: getNriRllFilesListFx,
});

// заполнение $completeTpiData при переходе на 3 шаг, добавление количиства по умолчанию 1,
// расчет недостающих показателей (sum, sum_tax, total_sum)
sample({
    clock: jobProcessGateStage3.open,
    source: $selectedTpiData,
    fn: (source) => {
        return source.map((item) => {
            const result = {...item} as TpiSpecificationOutputType;
            result.quantity = 1;
            result.sum = +(result.quantity * +result.price * +result.coefficient).toFixed(2);
            result.sum_tax = result.is_tax ? +(result.sum * 0.2).toFixed(2) : 0;
            result.total_sum = result.sum + result.sum_tax;
            result.tpi_specification_id = result.id ?? '';
            result.job_task_coefficients = [];
            result.id = undefined;
            result.coefficient_ams = undefined;
            result.coefficient_foundation = undefined;
            result.is_agreed_price = undefined;
            result.is_ams = undefined;
            result.is_foundation = undefined;
            result.is_prepayment = undefined;
            result.begin_date = undefined;
            result.sort_order = undefined;
            result.end_date = undefined;
            result.accuracy = undefined;
            result.type_position = undefined;
            return result;
        });
    },
    target: $jobTaskSpecifications,
});

//пересчет спецификации при изменении кол-ва или удалении строк
sample({
    clock: recalculateJobTaskSpecifications,
    source: $jobTaskSpecifications,
    fn: (source) => {
        return source.map((item) => {
            const result = {...item} as TpiSpecificationOutputType;
            result.sum = +(result.quantity * result.price * result.coefficient).toFixed(2);
            result.sum_tax = result.is_tax ? +(result.sum * 0.2).toFixed(2) : 0;
            result.total_sum = result.sum + result.sum_tax;
            return result;
        });
    },
    target: $jobTaskSpecifications,
});

//Подсчет общей суммы без НДС
sample({
    source: $jobTaskSpecifications,
    fn: (source) => source.reduce((sum, item) => sum + item.sum, 0),
    target: $totalSum,
});

//Подсчет итоговой суммы НДС
sample({
    source: $jobTaskSpecifications,
    fn: (source) => source.reduce((sumTax, item) => sumTax + item.sum_tax, 0),
    target: $totalTax,
});

//Пересчет итоговой суммы с НДС
sample({
    source: combine({totalSum: $totalSum, totalTax: $totalTax}),
    fn: (source) => source.totalTax + source.totalSum,
    target: $totalSumWithTax,
});

//пересчет сумм после введения нового количества в строке
sample({
    clock: setQuantity,
    source: $jobTaskSpecifications,
    fn: (source, clock) =>
        source.map((item) => {
            if (item.tpi_specification_id === clock.id) {
                item.quantity = clock.value;
                item.sum = +(item.quantity * item.price * item.coefficient).toFixed(2);
                item.sum_tax = +(item.sum * 0.2).toFixed(2);
                item.total_sum = item.sum + item.sum_tax;
            }
            return item;
        }),
    target: $jobTaskSpecifications,
});

// удаление строки
sample({
    clock: deleteRow,
    source: $jobTaskSpecifications,
    fn: (source, id) => source.filter((item) => item.tpi_specification_id !== id),
    target: $jobTaskSpecifications,
});

//при удалении строки, удаляется выделение на 2 шаге
sample({
    clock: deleteRow,
    source: $selectedTpiData,
    fn: (source, id) => source.filter((item) => item.id !== id),
    target: $selectedTpiData,
});

//Создание задания
sample({
    clock: createNewTask,
    source: combine({
        dispatchingStore: $dispatchingStore,
        attachmentFilesList: $attachmentFilesList,
    }),
    fn: ({dispatchingStore, attachmentFilesList}) =>
        prepareJobForCreation(dispatchingStore, attachmentFilesList),
    target: createNewTaskFx,
});

//Запись jobId для редиректа пользователя на страницу созданного задания
sample({
    clock: createNewTaskFx.doneData,
    filter: (clock) => clock?.status === 201,
    fn: (clock) => clock?.data.id ?? null,
    target: $createdJobId,
});

//Установка успешного флага создания задания
sample({
    clock: createNewTaskFx.doneData,
    filter: (clock) => clock?.status === 201,
    fn: () => true,
    target: $successfulJobCreation,
});

// скачивание ИД по БС
sample({
    clock: downloadNriBsFile,
    target: downloadNriBsFileFx,
});

// скачивание ИД по РЛЛ
sample({
    clock: downloadNriRllFile,
    target: downloadNriRllFileFx,
});

// отправка прикрепленного файла на бэк
sample({
    clock: attachFile,
    filter: (clock) => clock.length > 0,
    fn: (clock) => clock[0],
    target: sendAttachmentFileFx,
});

// скачивание ранее загруженного файла
sample({
    clock: downloadAttachmentFile,
    target: downloadAttachmentFileFx,
});
