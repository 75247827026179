import styled, {css} from 'styled-components/macro';
import {TableString} from 'shared/ui/Table/Table';

export const ProjectTableWrap = styled.div`
    overflow-x: auto;
    width: 100%;
    flex-shrink: 1;
`;

export const ProjectString = styled(TableString)<{clickable?: boolean}>`
    ${(props) =>
        props.clickable &&
        css`
            &:hover {
                background-color: #ededef;
            }

            cursor: pointer;
        `}
`;

const FilterStringMixin = (props) => css`
    background-color: transparent;
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;

    .icon {
        width: 100%;
        height: 100%;
        color: var(--secondary);
        transition-duration: 0.4s;
    }

    &:hover {
        .icon {
            color: var(--body-color);
        }
    }

    ${props.reverse &&
    css`
        transform: scaleY(-1);
    `}
`;

export const FilterString = styled.button`
    ${(props) => FilterStringMixin(props)}
`;
